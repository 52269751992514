<template>
<div class="container">
	<div>
		<MedicalHeader></MedicalHeader>
		<div class="tab-content">
			<div id="summary-section" class="tab-pane active" role="tabpanel" aria-labelledby="summary">
				<MedicalSummary v-if="user.foster_view_medical" :animal="animal"></MedicalSummary>
                <WeightHistory :animal="animal"/>
			</div>
			<div id="medications-section" class="tab-pane" role="tabpanel" aria-labelledby="medications">
				<Medications :animal="animal"></Medications>
			</div>
			<div id="vaccinations-section" class="tab-pane" role="tabpanel" aria-labelledby="vaccinations">
				<Vaccinations :animal="animal"></Vaccinations>
			</div>
			<div id="diagnostics-section" class="tab-pane fade" role="tabpanel" aria-labelledby="diagnostics">
				<Diagnostics :animal="animal"></Diagnostics>
			</div>
			<div id="surgeries-section" class="tab-pane fade" role="tabpanel" aria-labelledby="surgeries">
				<Surgeries :animal="animal"></Surgeries>
			</div>
			<div id="exams-section" class="tab-pane" role="tabpanel" aria-labelledby="exams">
				<AnimalProfileMedical :animal="animal"></AnimalProfileMedical>
			</div>
		</div>
	</div>
	
</div>
</template>

<script>
import { mapState } from 'vuex';
import MedicalHeader from './MedicalHeader'
import MedicalSummary from './summery/MedicalSummary'
import Medications from './medications/Medications'
import Vaccinations from './vaccinations/Vaccinations'
import Diagnostics from './diagnostics/diagnostics'
import Surgeries from './surgeries/surgeries'
import AnimalProfileMedical from './AnimalProfileMedical'
import WeightHistory from './weights/weight-history.vue';

export default {
	components: {
		MedicalHeader,
        MedicalSummary,
        WeightHistory,
        Surgeries,
        AnimalProfileMedical,
        Medications,
        Vaccinations,
        Diagnostics
	},
	data() {
		return {
			animal_id: this.$route.params.animal_id,
		}
	},
	mounted() {
	},
	computed: {
		...mapState({
			user: 'user',
			animal: state => state.animal || {},
		}),
	},
	methods: {
		
	},
}
</script>

<style scoped>

</style>
