	import Vue from 'vue'
	import Vuex from 'vuex'
	import axios from 'axios'
	import config from './config'

	Vue.use(Vuex)

	const url = config.API_URL

	export default new Vuex.Store({
		state: {
			user: null,
			animals: null,
			availableAnimals : null,
			animal: null,
			getWindowSizeFlag: false,
			pendingInvite: null,
			url : url,
			idleVue : null,
		},
		mutations: {
			SET_USER_DATA(state, userData) {
				state.user = userData
				localStorage.setItem('user', JSON.stringify(userData))
				axios.defaults.headers.common['Authorization'] = userData.token
			},
			CLEAR_USER_DATA() {
				localStorage.removeItem('token')
				localStorage.removeItem('user')
				axios.defaults.headers.common['Authorization'] = undefined
				location.reload()
			},
			SET_SHELTER_DATA(state, data) {
				let user = localStorage.getItem('user')
				user = JSON.parse(user);
				user.shelter_id = data.shelter._id
				user.shelter_name = data.shelter.shelter
				user.people_id = data.shelter.people_id
				user.foster_adopt_requests = data.settings.foster_adopt_requests
				user.foster_edit_animal_data = data.settings.foster_edit_animal_data
				user.foster_edit_bio = data.settings.foster_edit_bio
				user.foster_edit_photos = data.settings.foster_edit_photos
				user.foster_edit_videos = data.settings.foster_edit_videos
				user.foster_view_medical = data.settings.foster_view_medical
				user.foster_view_staff_notes = data.settings.foster_view_staff_notes
				state.user = user
				localStorage.setItem('user', JSON.stringify(user))
			},
			SET_ANIMALS(state, animalsData) {
				state.animals = animalsData
			},
			SET_AVAILABLE_ANIMALS(state, animalsData) {
				state.availableAnimals = animalsData
			},
			SET_ANIMAL(state, animalData) {
				state.animal = animalData
			},
			UPDATE_ANIMAL(state, animalData) {
				state.animal = { ...state.animal, ...animalData }
			},
			SET_INVITE(state, inviteData) {
				state.pendingInvite = inviteData
			},
			SET_WINDOW_SIZE_FLAG(state, data){
				state.getWindowSizeFlag = data
			}
		},
		getters: {
			photoUrl (state){
				return config.PHOTO_URL
			},
			tinymce_key(state){
				return config.MCE_KEY
			},
			isLoggedIn (state) {
				return !!state.user
			},
			shelter (state){
				return state.shelter
			},
			people_id (state){
				return state.people_id
			},
			pendingInvite (state){
				return state.pendingInvite
			},
		},
		actions: {
			login({ commit }, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/auth/login',
						data,
						method: 'POST',
					})
					.then((resp) => {
						commit('SET_USER_DATA', resp.data.user)
						resolve(resp)
					})
					.catch((err) => {
						localStorage.removeItem('token')
						console.log('store err', err.response)
						reject(err)
					})
				})
			},
			register({ commit }, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/auth/register',
						data,
						method: 'POST',
					})
					.then((resp) => {
						const user = resp.data
						commit('SET_USER_DATA', user)
						resolve(resp)
					})
					.catch((err) => {
						localStorage.removeItem('token')
						reject(err)
					})
				})
			},
			requestResetPassword({}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/auth/requestResetPassword',
						data,
						method: 'POST',
					})
					.then((resp) => resolve(resp))
					.catch((err) => reject(err))
				});
			},
			resetPassword({}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/auth/resetPassword',
						data,
						method: 'POST',
					})
					.then((resp) => resolve(resp))
					.catch((err) => reject(err))
				});
			},
			logout({ commit }) {
				commit('CLEAR_USER_DATA')
			},
			shelter({ commit }, data ) {
				let people_id = data.people_id
				let shelter_id = data.shelter_id

				console.log('$store shelter : ', shelter_id, people_id)

				return new Promise((resolve, reject) => {
					axios
					.get(`${url}/api/shelter/${shelter_id}/${people_id}`)
					.then((resp) => {
						const data = resp.data
						data.shelter.people_id = people_id
						console.log('shelter store', data)
						commit('SET_SHELTER_DATA', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			getAnimals({ commit }, data) {
				return new Promise((resolve, reject) => {
					axios
					.get(url+'/api/animals/'+data.people_id)
					.then((resp) => {
						const data = resp.data
						commit('SET_ANIMALS', data)
						resolve(resp)
					})
					.catch((err) => {
						console.log('getAnimals Error', err)
						reject(err)
					})
				})
			},
			getAvailableAnimals({ commit }, data) {
				return new Promise((resolve, reject) => {
					axios
					.get(url+'/api/animals/available/'+data.shelter_id+'/'+data.page)
					.then((resp) => {
						const data = resp.data
						console.log('getAvailableAnimals store', data)
						commit('SET_AVAILABLE_ANIMALS', data)
						resolve(resp)
					})
					.catch((err) => {
						console.log('getAvailableAnimals Error', err)
						reject(err)
					})
				})
			},
			getAnimal({ commit }, data) {
				return new Promise((resolve, reject) => {
					axios
					.get(url+'/api/animal/'+data.animal_id)
					.then((resp) => {
						const data = resp.data
						commit('SET_ANIMAL', data)
						resolve(resp)
					})
					.catch((err) => {
						console.log('getAnimals Error', err.response)
						reject(err)
					})
				})
			},
			updateAnimal({ commit }, { animal_id, data }) {
				return new Promise((resolve, reject) => {
					axios
					.put(`${url}/api/animal/${animal_id}`, data)
					.then((resp) => {
						const data = resp.data
						commit('UPDATE_ANIMAL', data)
						resolve(resp)
					})
					.catch((err) => {
						console.log('updateAnimal Error', err.response)
						reject(err)
					})
				})
			},
			addNotes({ dispatch }, data) {
				return new Promise((resolve, reject) => {
					axios({
						url:`${url}/api/animal/add-history`,
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
						
					})
					.catch((err) => {
						reject(err)
					})
				})
				
			},
			addComment({ dispatch },{data,animal_id}) {
				return new Promise((resolve, reject) => {
					axios({
						url:`${url}/api/animal/add-history-comments`,
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', {
							animal_id:animal_id
						})
						resolve(resp)
						
					})
					.catch((err) => {
						reject(err)
					})
				})
				
			},
			updateNotes({ dispatch },{data,animal_id}) {
				return new Promise((resolve, reject) => {
					axios({
						url:`${url}/api/animal/update-history`,
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', {
							animal_id:animal_id
						})
						resolve(resp)
						
					})
					.catch((err) => {
						reject(err)
					})
				})
				
			},
			deleteComment({ dispatch },{data,animal_id}) {
				return new Promise((resolve, reject) => {
					axios({
						url:`${url}/api/animal/delete-history-comment`,
						data,
						method: 'DELETE',
					})
					.then((resp) => {
						dispatch('getAnimal', {
							animal_id:animal_id
						})
						resolve(resp)
						
					})
					.catch((err) => {
						reject(err)
					})
				})
				
			},
			deleteNote({ dispatch },{data,animal_id}) {
				return new Promise((resolve, reject) => {
					axios({
						url:`${url}/api/animal/delete-history`,
						data,
						method: 'DELETE',
					})
					.then((resp) => {
						dispatch('getAnimal', {
							animal_id:animal_id
						})
						resolve(resp)
						
					})
					.catch((err) => {
						reject(err)
					})
				})
				
			},
			updateComment({ dispatch },{data,animal_id}) {
				return new Promise((resolve, reject) => {
					axios({
						url:`${url}/api/animal/update-history-comment`,
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', {
							animal_id:animal_id
						})
						resolve(resp)
						
					})
					.catch((err) => {
						reject(err)
					})
				})
				
			},
			updateMedical({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/medical/update',
						data,
						method: 'POST',
					})
					.then((resp) => {
						console.log('update medical data', data)
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			addMedical({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/medical/add',
						data,
						method: 'POST',
					})
					.then((resp) => {
						console.log('add medical store', data)
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			deleteMedical({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/medical/delete',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			addVideoUrl({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/video/add',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			deleteVideo({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/video/delete',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			sortVideos({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/video/sort',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			checkInvite({ commit }, code) {
				return new Promise((resolve, reject) => {
					axios
					.get(`${url}/api/invites/check?code=${code}`)
					.then((resp) => {
						commit('SET_INVITE', resp.data)
						resolve(resp)
					})
					.catch((err) => {
						console.log('checkInvite Error', err.response)
						reject(err)
					})
				})
			},
			acceptInvite({ commit }, code) {
				return new Promise((resolve, reject) => {
					axios
					.post(`${url}/api/invites/accept`, { code })
					.then((resp) => {
						commit('SET_INVITE', null)
						resolve(resp)
					})
					.catch((err) => {
						console.log('acceptInvite Error', err.response)
						reject(err)
					})
				})
			},
			getMedicalForm({ }, form_id) {
				return new Promise((resolve, reject) => {
					console.log('getMedical', form_id)
					axios
					.get(`${url}/api/medical/form/${form_id}`)
					.then((resp) => {
						resolve(resp)
					})
					.catch((err) => {
						console.log('checkInvite Error', err.response)
						reject(err)
					})
				})
			},
			declineRequest({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/request/decline',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			approveRequest({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/request/approve',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			undeclineRequest({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/request/undecline',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			unapproveRequest({dispatch}, data) {
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/request/unapprove',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			emailAdoptLink({dispatch}, data) {
				return new Promise((resolve, reject) => {
				 	 axios.post(url+'/api/animal/email-adopt-link',data)
					.then((resp) => {
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			},
			completeAdoption({dispatch}, data){
				return new Promise((resolve, reject) => {
					axios({
						url: url+'/api/outcome/complete-adoption',
						data,
						method: 'POST',
					})
					.then((resp) => {
						dispatch('getAnimal', data)
						resolve(resp)
					})
					.catch((err) => {
						reject(err)
					})
				})
			}
		}
	})