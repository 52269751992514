<template>
    <div class="row">
        <div :class="classSize">
            <p>{{ item.text }}</p>
        </div>
    </div>
</template>
<script>
    export default {
        props : {
            item :{
                type : [Object, Array],
                required :true,
            },
            classSize: {
                type : String,
                default : 'col-sm-3'
            },
            headerSize: {
                type : String,
                default : 'h3'
            },
        },

        data () {
            return {
                
            }
        },
        created(){
            
        },
    }
</script>

<style scoped>

</style>