<template>
    <div>
        <h5>{{field.label}}</h5>
        <p>{{microchipIssuer}}</p>
    </div>
</template>

<script>
export default {
    name: 'BaseMicrochipField',
    components: {  },
    directives: {  },
    props : {
        field: {
            type : Object,
            required :true
        },
        microchip : {
            type : String,
        },
        microchipIssuer : {
            type : String,
        }   
    },
    data() {
        return {
            
    
        };
    },
    mounted() {
        
    },
    computed :{
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>