<template>
    <div>
      <div class="card-header border-0">
        <div :class="{ 'active': closed }"  aria-haspopup="true" aria-expanded="false">
          <h4>
            <span
              class="badge text-capitalize"
              :class="{
                'badge-success': diagnostic.status === 'active',
                'badge-info': diagnostic.status === 'completed',
                'badge-danger': diagnostic.status === 'past due',
                'badge-warning': diagnostic.status === 'canceled'
              }"
            >
              {{ diagnostic.status }}
            </span>
             {{ diagnostic.date | formatDate }} - {{ diagnostic.template_name }}
             <br>
            <a
             class="collapse-link"
              data-toggle="collapse"
              :data-target="'#collaspeDiagnostic' + diagnostic._id"
              @click="closed = !closed"
            >
             <span v-if="!closed">Show Detail</span>
              <span v-else>Hide Detail</span>
            </a>
          </h4>
        </div>
      </div>
      <div
        class="card-body collapse in"
        :id="'collaspeDiagnostic' + diagnostic._id"
      >
        <div class="row">
          <div class="col-lg-7">
            <div class="row mb-2">
              <div class="col-lg-4"><strong>Date:</strong></div>
              <div class="col-lg-8">
                <span v-if="diagnostic.date">{{ diagnostic.date | formatDate }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-4"><strong>Product:</strong></div>
              <div class="col-lg-8">
                <span v-if="diagnostic.product">{{ diagnostic.product }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-4"><strong>Manufacturer:</strong></div>
              <div class="col-lg-8">
                <span v-if="diagnostic.manufacturer">{{ diagnostic.manufacturer }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-4"><strong>Notes:</strong></div>
              <div class="col-lg-8">
                <span class="paragraphBreak" v-if="diagnostic.notes">{{ diagnostic.notes }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DiagnosticResult
            :diagnostic="diagnostic"
          ></DiagnosticResult>
          <DiagnosticFollowupResult
            v-if="diagnostic.followup_test"
            :diagnostic="diagnostic"
          ></DiagnosticFollowupResult>
        </div>
      </div>
    </div>
</template>


<script>

    import DiagnosticResult from './diagnostic-result.vue';
    import DiagnosticFollowupResult from './diagnostic-followup-result.vue';

    export default {
        name: 'medical_diagnostic',

        components: {
            DiagnosticResult,
            DiagnosticFollowupResult,
        },
         props : {
            diagnostic : {
                type : Array|Object,
                required :true,
            },
        },
        data: function () {
            return { 
                closed :  false,
            }
        },
    } 

</script>
<style scoped>
    .collapse-link{
        cursor: pointer;
        font-size: 12px;
        margin-top: 10px;
        
    }
    .card-header h4 {
        text-wrap: balance;
    }
    .active::after {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
    .dropdown-toggle:not(.dropdown-toggle-empty)::after {
    padding-right: 14px;
    }

</style>