<template>
  <div>
    <div id="nav">
      <NavBar />
    </div>
    <main id="content" role="main">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { authComputed } from '../store/helpers.js'
import NavBar from './../components/NavBar'
export default {
	name: 'availableAanimals',
	components: {
		NavBar,
	},
	data() {
		return {
		}
	},
	computed: {
		...authComputed,
	},
}
</script>