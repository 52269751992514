<template>
  <div>
    <div id="nav">
      <NavBar />
    </div>
    <main id="content" role="main" class="text-center p-3">
      <div v-if="pendingInvite && pendingInvite.shelter">
        <img :src="pendingInvite.shelter.logo" class="img-fluid text-center shelter-logo mb-3" style="height:100px">
        <h5>You have been invited to the "{{ pendingInvite.shelter.shelter }}" shelter. Please click the button below to accept the invite.</h5>
        <button class="btn btn-primary transition-3d-hover my-3" @click="acceptInvite">Accept</button>
      </div>
      <p class="text-danger" v-else>
        {{ this.inviteCode ? error || 'Invalid Invite Code' : 'Missing Invite Code' }}
      </p>
      <router-link to="/animals" name="animals" class="font-size-1 font-weight-bold" tag="a">
          Go to Animals Page
        </router-link>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from './../components/NavBar'

export default {
  name : 'invites',
	components: {
		NavBar,
	},
	data() {
		return {
      inviteCode: this.$route.query.invite_code || '',
      error: null,
		}
	},
	computed: {
		...mapGetters(['pendingInvite']),
	},
  mounted() {
    if(this.inviteCode) {
      this.$store.dispatch('checkInvite', this.inviteCode)
        .then(() => {})
        .catch((err) => {
          this.error = err.response.data
        })
    }
  },
  methods: {
    acceptInvite(){
      this.error = null;
      let data = {}
      data.people_id = this.pendingInvite.invite.people_id
      data.shelter_id = this.pendingInvite.invite.shelter_id

      this.$store
        .dispatch('acceptInvite', this.inviteCode || this.pendingInvite.invite.code)
        .then((response) => {
          this.getShelter(data)
        })
        .catch((err) => {
          console.log('accept error', err)
          this.error = err.response.data
        })
    },
    getShelter(data){      
      this.$store
      .dispatch('shelter', data)
      .then((response) => {
          this.$router.push('/animals')
      })
      .catch((err) => {
        console.log('accept error', err)
        this.error = err.response.data
      })
    }
  },
}
</script>
