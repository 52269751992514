<template>
    <div class="bg-whitesmoke p-2">
        <div class="social-footer">
            <div class="social-comment m-b-xxs" v-if="comment.status == 'active'">
                <div class="pull-left d-flex">
                    <div class="d-inline-flex justify-content-center align-items-center">
                        <img @error="showUserIcon=true" v-if="!showUserIcon" class="avatar-img img-circle mr-2 mb-2" 
                            :src="comment.profile_photo ? '/shelter/files/get-file/' + item.shelter_id + '?file=' + item.shelter_id+'/users/'+comment.user_id+'/'+comment.profile_photo : '/storage/noperson.png'"
                            alt="Image Description" />
                            <font-awesome-icon
                            class="list-group-icon mr-2 mb-2 d-inline-flex justify-content-center align-items-center"
                            icon="user"
                            v-else
                        />
                        <p class="pt-2" v-if="comment.user_id==user._id" >You </p>
                        <p v-else >{{comment.user_name}} </p>
                    </div>
                </div>
                <div class="media-body">
                    <div class="d-flex" @click="editComment()"  v-if="!edit" :class="{ 'comments': comment.user_id === user._id }" >
                        <p class="p-1 mb-0"> {{content}}</p>
                    </div>
                    <div v-else class="col-12">
                        <input type="text" class="form-control mb-2" placeholder="Write a comment.." v-model="content">
                        <span class="" @click="edit =! edit, updateComment()"> <button class="btn btn-xs btn-primary m-1">Save</button> </span>
                        <span class="" @click="deleteComment()"> <button class="btn btn-xs btn-danger m-1">Delete</button> </span>
                        <span class="" @click="edit =! edit"> <button class="btn btn-xs btn-default m-1">Cancel</button> </span>
                    </div>
                    <small v-show="!edit" class="text-muted">{{comment.updated_date | formatDateTime}}</small>
                    <br/>
                </div>
            </div>
        </div>                    
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        props : {
            comment : {
                type : Array|Object,
                required :true,
            }, 
            item : {
                type : Object,
                required :false
            }, 
        },

        data () {
            return {
                edit : false,
                showEdit : false,
                editClass : false,
			    showUserIcon: false,
                content : this.comment.comment,
            }
        },
        computed :{
		    ...mapState(['user', 'animal']),
        },
        methods: {
            deleteComment(){
                var data = {};
                data['id'] = this.item._id;
                data['comment_id'] = this.comment.comment_id;
                const animal_id = this.animal._id;
                this.$store
				.dispatch('deleteComment', {data,animal_id})
				.then((response) => {
					this.edit= false;
				})
				.catch((err) => {
				console.log(err)
				this.error = err.response.data
			    })
            },
            editComment(){
                if(this.user._id==this.comment.user_id)
                    this.edit = true;
            },
            updateComment(){
                var data = {};
                data['id'] = this.item._id;
                data['comment_id'] = this.comment.comment_id;
                data['comment'] = this.content;
                const animal_id = this.animal._id;
                this.$store
				.dispatch('updateComment', {data,animal_id})
				.then((response) => {
					this.edit= false;
				})
				.catch((err) => {
				console.log(err)
				this.error = err.response.data
			    })

            },
        }
    }
</script>

<style scoped>
.bg-whitesmoke{
    background: #fcf7f782;
    border-radius: 2px;
}

.edit-border:hover {
    border: 1px solid #d5dce0;
}


.social-footer .social-comment img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}
.comments:hover{
    outline:1px solid rgb(197, 195, 195);
    cursor: pointer;
}

</style>