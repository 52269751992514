<template>
<div>
	<div class="container space-top-2">
		<div class="row align-items-center mb-5">
			<div class="col-sm mb-3 mb-sm-0">
				<h2 class="h2 mb-0">Files</h2>
			</div>
		</div>
		<div class="row">
            <div class="col-lg-12">
                <div class="example-drag">
                    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                        <h3>Drop files to upload</h3>
                  </div>
                </div>
            </div>
        </div>
		<div class="row" v-if="user">
			<div class="col-lg-12">
				<div class="example-simple">
					<div class="upload">
						<ul>
							<li v-for="(file) in newFiles" :key="file.id">
								<span>{{file.name}}</span> -
								<span>{{file.size |formatSize }}</span> -
								<span v-if="file.error" class="text-danger">{{file.error}}</span>
								<span v-else-if="file.success">success</span>
								<span v-else-if="file.active">active</span>
								<span v-else-if="!!file.error">{{file.error}}</span>
								<span v-else></span>
							</li>
						</ul>
						<div class="example-btn" >
							<file-upload
								ref="upload"
								:input-id="'collection'"
								class="click-drop-area "
								:post-action="post_action"
								extensions="gif,jpg,jpeg,png,webp,pdf,doc,docx,xls,xlsx,csv,txt"
								accept="image/png,image/gif,image/jpeg,image/webp,text/*,application/*,.doc,.docx,application/pdf,.xls,.xlsx,"
								:multiple="true"
								:size="1024 * 1024 * 8"
								:data ="{ 'animal_id' : animal._id, 'destinationPath': '/'+this.animal.shelter_id+'/'+this.animal._id + '/files'}"
								:add-index="true"
								v-model="newFiles"
								:headers="{'Authorization' : `${this.user.token}`}"
								@input-filter="inputFilter"
								@input-file="inputFile"
								:drop="true"
								>
								<slot name="uploadMessage">
									<a class="link-collapse-default">Click or Drop files here to upload</a>
								</slot>
							</file-upload>
						</div>
						<span v-show="!sizeError && this.$refs.upload && this.$refs.upload.value.length && this.$refs.upload.uploaded">All files have been uploaded</span>
						<span class="text-danger" v-show="sizeError">
							The file you are attempting to upload is greater than 8MB and is too large. Please reduce the file size to upload.
						</span>
					</div>
				</div>
			</div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="list-group list-group-lg list-group-flushx list-group-no-guttersx">
					<div v-if="animal.files && animal.files.length">
						<div class="list-group-item" v-for="(file, index) in animal.files" :key="index">
							<div class="media mb-2">
								<font-awesome-icon class="list-group-icon" v-if="isImage(file.filename)" icon="image"/>
								<font-awesome-icon class="list-group-icon" v-else icon="file"/>
								<div class="media-body">
									<div class="row align-items-center">
										<div class="col-sm mb-1 mb-sm-0">
											<h5 class="mb-0" v-if="file.type == 'rabies'">
												<a :href="file.path" target="_blank">
												{{file.filename | truncate(48, '...') }}</a>
											</h5>
											<h5 class="mb-0" v-else-if="file.type == 'spay_neuter'">
												<a :href="file.path" target="_blank">
												{{file.filename | truncate(48, '...') }}</a>
											</h5>
											<h5 class="mb-0" v-else>
												<a class="btn-link" @click="openFile(file.path)" role="button">{{file.filename | truncate(48, '...') }}</a>
											</h5>
											<small class="text-capitalize">{{file.type}}</small>
											<small> - </small>
											<small>{{file.created_at}}</small>
										</div>
										<div class="col-sm-auto text-danger">
											<font-awesome-icon icon="trash" data-toggle="modal" :data-target="'#deleteFileModal_'+index" @click="deleteModal(file, index)"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-5" v-else>
						<h3>{{animal.animal_name}} does not have any files.</h3>
					</div>
				</div>
			</div>
		</div>

		<DeleteFileModal
			v-if="showDeleteModal"
			:key="'deleteModalId'+componentKey"
			:id="deleteModalId"
			:title="'Delete File'"
			:action="'Delete'"
			:color="'btn-danger'"
			:file="fileToDelete"
			@deleteFile="deleteFile"
			:index="'action_'+componentKey"
			:shelter_id="animal.shelter_id"
		>
		</DeleteFileModal>
		
	</div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import FileUpload from 'vue-upload-component'
import mime from 'mime';

import DeleteFileModal from './DeleteFileModal'
import axios from 'axios'
import config from '../../../store/config'

export default {
	name: 'AnimalProfileAbout',
	components: {FileUpload, DeleteFileModal},
	directives: {},
	props : {
		animal: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			sizeError : false,
			files : [],
			newFiles : [],
			collection : 'animals',
			destinationPath : '/'+this.animal.shelter_id+'/'+this.animal._id + '/files',
			showDeleteModal : false,
			fileToDelete : {},
			post_action : `${config.API_URL}/api/file/upload`
		}
	},
	created(){ },
	mounted(){ },
	computed :{
		...mapState(['user']),
		
	},
	methods: {
		deleteModal(file, index){
			this.fileToDelete = file
			this.showDeleteModal = true;
			this.componentKey+= 1
			this.deleteModalId = 'deleteFileModal_'+index
		},
		deleteFile(filename){
			console.log('deleteFile')
			axios
			.post(`${config.API_URL}/api/file/delete`, {animal_id: this.$route.params.animal_id, filename : filename})
			.then((resp) => {
				this.getAnimal()
				console.log('file', resp)
			})
			.catch((err) => {
				console.log('file', err)
			})
		},
		getAnimal(){
			this.$store.dispatch('getAnimal', {animal_id: this.$route.params.animal_id})
			.then((response) => {
				this.isLoading = false
			})
			.catch((err) => console.log(err))
		},
		isImage(filename){
			var exts = ['png', 'jpg', 'jpeg', 'gif'];
			var fileExtension = filename ? filename.split('.').pop() : '';
			return exts.indexOf(fileExtension) > -1 ? true : false
		},
		openFile(filePath) {
			// window.open(`${config.API_URL}/api/file/get?token=${axios.defaults.headers.common['Authorization']}&filePath=${encodeURIComponent(filePath)}`, '_blank');
			axios
				.get(`${config.API_URL}/api/file/get`, { params: { filePath }, responseType: 'blob' })
				.then((resp) => {

					const filename = filePath.split('/').pop()
					const extension = filename.split('.').pop().toLowerCase()

					if (['jpg', 'jpeg', 'png', 'pdf'].includes(extension)) {
						// if it's a pdf or an image file, open a preview.
						const mimeType = mime.getType(filename)
						console.log('mime type: ', mimeType)
						const url = window.URL.createObjectURL(new Blob([resp.data], { type: mimeType }))
						window.open(url, '_blank')
					} else { // if not a pdf file, just trigger a download
						const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'octet/stream' }))
						const a = document.createElement('a')
						a.style = 'display: none'
						a.href = url
						a.download = filename
						document.body.append(a)
						a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
						setTimeout(() => {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(url)
							a.remove();
						}, 100)
					}

					return resp.data
				})
				.catch((err) => {
					// need to parse the error as it's of blob type.
					console.log('file error', err.response)
					const reader = new FileReader()

					// This fires after the blob has been read/loaded.
					reader.addEventListener('loadend', () => alert(reader.result))

					// Start reading the blob as text.
					reader.readAsText(err.response.data)
				})
		},
		inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				// Before adding a file
				// Filter system files or hide files
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
					return prevent()
				}
				// Filter php html js file
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent()
				}
			}
		},
		inputFile(newFile, oldFile) {
			this.$refs.upload.active = true;  //automatic upload
			if (newFile && oldFile) {
				if (newFile.error !== oldFile.error) {
					console.log('input error', newFile.error, newFile)
					this.$refs.upload.update(newFile, {error: newFile.error})
					let vm = this
					setTimeout(function () {
						vm.$refs.upload.clear();
					}, 5000);
				}

				// Uploaded successfully
				if (newFile.success !== oldFile.success) {
					console.log('success', newFile.success, newFile)

					this.$store.dispatch('getAnimal', {animal_id: this.$route.params.animal_id,})
					.then((response) => {
						this.isLoading = false
					})
					.catch((err) => console.log(err))

					let vm = this
					setTimeout(function () {
						vm.$refs.upload.clear();
					}, 3000);
				}
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.file-box {
    float: left;
    width: 180px;
}

.example-drag label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.example-drag .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>