var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row m-b-xxs"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"table-responsive tableFixHead"},[_c('table',{staticClass:"table table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.vaccination.schedule),function(dose,index){return _c('tr',{key:dose.id},[(index == 0 )?_c('td',[_vm._v("Initial Dose")]):_c('td',[_vm._v("Booster "+_vm._s(index))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(dose.date)))]),(dose.status=='pending' && _vm.pastDue(dose))?_c('td',{staticClass:"text-capitalize"},[_c('span',{staticClass:"label label-danger"},[_vm._v(" Past Due ")])]):_c('td',{staticClass:"text-capitalize"},[_c('span',{staticClass:"badge",class:{
                                    'badge-primary': dose.status === 'pending',
                                    'badge-info': dose.status === 'completed',
                                    'badge-danger': dose.status === 'skipped',
                                    'badge-warning': dose.status === 'canceled'
                                    }},[_vm._v(" "+_vm._s(dose.status)+" ")])]),(dose.lot)?_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(dose.lot))]):_c('td',[_vm._v(" - ")]),(dose.expiration_date)?_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm._f("formatDate")(dose.expiration_date)))]):_c('td',[_vm._v(" - ")]),(dose.by)?_c('td',[_vm._v(_vm._s(dose.by))]):_c('td',[_vm._v(" - ")]),(dose.notes)?_c('td',[_vm._v(_vm._s(dose.notes))]):_c('td',[_vm._v(" - ")])])}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5 mt-3 mb-2"},[_c('strong',[_vm._v("Vaccination Doses:")]),_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Lot #")]),_c('th',[_vm._v("Expiration Date")]),_c('th',[_vm._v("By")]),_c('th',[_vm._v("Notes")])])])
}]

export { render, staticRenderFns }