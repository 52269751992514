<template>
    <div class="container p-0 space-top-2">
        <div class="row col-12 mb-3 m-0">
            <div v-if="animal.medicalDiagnostics.length == 0"> 
                <div class="col-lg-12 text-center m-b-md"> You do not have any Diagnostics </div>
            </div>  
            <div v-else class="card card-bordered card-hover-shadow mb-5 pt-3 pl-3 float-e-margins p-0 col-12" v-for="(diagnostic, index) in animal.medicalDiagnostics" :key="diagnostic._id">
                <Diagnostic :diagnostic="diagnostic"></Diagnostic>
            </div>
        </div>
    </div>
</template>
<script>
    import Diagnostic from './diagnostic.vue';
    export default {
        name: 'Diagnostics',

        components: {
            Diagnostic,
        },
         props : {
            animal : {
                type : Array|Object,
                required :true,
            },
        },
        data: function () {
            return { 
                
            }
        },
    } 

</script>
<style scoped>
.row{
    font:menu;
}
.card{
    min-height: 131px;
}
</style>