<template>
<div>
    <div :id="id" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="id" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" :id="id">{{form.form_title}}</h3>
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                        <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                        </svg>
                    </button>
                </div>
                <ValidationObserver ref="addVideoForm" v-slot="{handleSubmit}">
                    <form  class="form-horizontal" 
                            id="form" 
                            enctype='multipart/form-data' 
                            action='add' 
                            @submit.prevent="handleSubmit(onSubmit)"
                        >
                        <div class="modal-body">
                            <h4 class="mb-3">Enter the YouTube or Vimeo video URL</h4>
                            <div class="col-lg-12">
                                <ValidationProvider rules="required" v-slot="v">
                                <div class="form-group">
                                    <label for="video_link" class="control-label">Video Url:</label>
                                    <input type="text"
                                        name="video_url" 
                                        id="video_url"
                                        class="form-control" 
                                        v-model="video_url"
                                        placeholder="https://youtubevideo.com"
                                        aria-label="Video Url"
                                        data-msg="Please enter a valid url address."
                                    >
                                    <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-white" data-dismiss="modal">Discard</button>
                            <button type="subumit" class="btn" :class="color">Submit</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</div>
</template>
    
    <script>
        import { ValidationObserver } from 'vee-validate';
        import _ from 'lodash';

    export default {
        name: 'AddVideoModal',
        components: { ValidationObserver },
        directives: {  },
        props: {
            id: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            color: {
                type: String,
                default: 'btn-primary',
            },
            animal : {
                type : Object,
                required : true,
            }
        },
        data() {
            return {
                form : {},
                video_url : '',
            };
        },
        mounted() {
        },
        computed :{
        },
        methods: {

            onSubmit(){
                this.submit();
            },
            async submit() {
                this.form.shelter_id = this.animal.shelter_id
                this.form.animal_id = this.animal._id
                this.form.video_url = this.video_url
    
                this.$store.dispatch('addVideoUrl', this.form)
                .then(response => {
                    this.showToaster('success', 'Video Added Successfull')
                    $("#"+this.id).modal("hide");
                })
                .catch((err) => console.log(err));
            },    
        }   
    }
    </script>
    
    <style lang="scss" scoped>
    
    </style>