<template>
  <div class="login">
    <main id="content" role="main">
      <!-- Login Form -->
      <div class="container space-2 space-lg-3">
        <form class="w-md-75 w-lg-50 mx-md-auto" @submit.prevent="submitRequest">
          <!-- Title -->
          <div class="mb-5 mb-md-7">
            <h1 class="h2 mb-3">Animals First - Foster App</h1>
            <h3 :class="{ 'text-success': success }">
              {{ success ?
                'A password reset email has been sent successfully. Pleaes check your inbox' :
                'Please provide your email address and we\'ll email you the reset link.'
              }}
            </h3>
          </div>
          <!-- End Title -->

          <!-- Form Group -->
          <div class="js-form-message form-group" v-if="!success">
            <label class="input-label" for="email">Email address</label>
            <input
              v-model="email"
              type="email"
              class="form-control"
              name="email"
              id="email"
              placeholder="Email address"
              aria-label="Email address"
              required
              data-msg="Please enter a valid email address."
            />
          </div>
          <!-- End Form Group -->

          <p v-if="error" class="text-danger">{{ error }}</p>
          <!-- Button -->
          <div class="row align-items-center mb-5">
            <div class="col-sm-6 mb-3 mb-sm-0">
              <span class="font-size-1 text-muted">Go back to</span>
              <router-link to="/login" class="ml-1">
                <a class="font-size-1 font-weight-bold" href>Login</a>
              </router-link>
            </div>

            <div class="col-sm-6 text-sm-right" v-if="!success">
              <button type="submit" class="btn btn-primary transition-3d-hover">Submit</button>
            </div>
          </div>
          <!-- End Button -->
        </form>
      </div>
      <!-- End Login Form -->
    </main>
  </div>
</template>

<script>
export default {
	props: {},
	components: {},
	data() {
		return {
			email: '',
      success: false,
			error: null,
		}
	},
	methods: {
		submitRequest() {
      this.success = false;
      this.error = null;

			this.$store
				.dispatch('requestResetPassword', { email: this.email })
				.then((response) => {
					if (response.data.success) {
            this.success = true;
          } else {
            this.error = 'Failed to perform this operation. Please try again later.';
          }
				})
				.catch((err) => {
					console.log('password reset request error', err.response)
					this.error = err.response.data
				})
		},
	},
}
</script>
