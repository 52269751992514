<template>
  <div>
      <div class="card-header border-0">
        <div class="row">
            <div  :class="{ 'active': closed }"   aria-haspopup="true" aria-expanded="false">
              <h4>
                <span
                      class="text-capitalize badge"
                      :class="{
                      'badge-success text-white': vaccination.status === 'active',
                      'badge-info text-white': vaccination.status === 'completed',
                      'badge-danger text-white': vaccination.status === 'past due',
                      'badge-warning text-white': vaccination.status === 'canceled',
                      }"
                  >
                    {{ vaccination.status }}
                  </span>
                  {{ vaccination.date | formatDate }} {{ vaccination.template_name }}
                  <br>
                  <a
                  class="collapse-link"
                  data-toggle="collapse"
                  :data-target="'#collapsevaccination' + vaccination._id"
                  @click="closed = !closed"
                  >
                    <span v-if="!closed">Show Detail</span>
                    <span v-else>Hide Detail</span>
                  </a>
              </h4>
            </div>
        </div>
      </div>
      <div class="card-body collapse in" :id="'collapsevaccination' + vaccination._id">
        <div class="col-9">
            <div class="row p-2">
            <div class="col-lg-4"><strong>Product:</strong></div>
            <div class="col-lg-8">
                <span v-if="vaccination.product">{{ vaccination.product }}</span>
                <span v-else>-</span>
            </div>
            </div>
            <div class="row p-2" v-if="vaccination.manufacturer">
            <div class="col-lg-4"><strong>Manufacturer:</strong></div>
            <div class="col-lg-8">
                <span class="pre-line" v-if="vaccination.manufacturer">{{ vaccination.manufacturer }}</span>
                <span v-else>-</span>
            </div>
            </div>
            <div class="row  p-2">
            <div class="col-lg-4"><strong>Vaccine Type:</strong></div>
            <div class="col-lg-8">
                <span v-if="vaccination.vaccine_type">{{ vaccination.vaccine_type }}</span>
                <span v-else>-</span>
            </div>
            </div>
            <div class="row  p-2">
            <div class="col-lg-4"><strong>Route:</strong></div>
            <div class="col-lg-8">
                <span v-if="vaccination.route">{{ vaccination.route }}</span>
                <span v-else>-</span>
            </div>
            </div>
            <div class="row  p-2">
            <div class="col-lg-4"><strong>Site:</strong></div>
            <div class="col-lg-8">
                <span v-if="vaccination.site">{{ vaccination.site }}</span>
                <span v-else>-</span>
            </div>
            </div>
            <div class="row  p-2">
            <div class="col-lg-4"><strong>Dosage:</strong></div>
            <div class="col-lg-8">
                <span v-if="vaccination.dosage">{{ vaccination.dosage }} {{ vaccination.dosage_unit }}</span>
                <span v-else>-</span>
            </div>
            </div>
            <div class="row  p-2">
            <div class="col-lg-4"><strong>Notes:</strong></div>
            <div class="col-lg-8">
                <span class="pre-line" v-if="vaccination.notes">{{ vaccination.notes }}</span>
                <span v-else>-</span>
            </div>
            </div>
        </div>
        <NextVaccination 
          :vaccination="vaccination"
        >
        </NextVaccination>
      </div>
    </div>
</template>

<script>
import NextVaccination from './vaccination/next-vaccination.vue';

export default {
  name: 'Vaccination',

  components: {
    NextVaccination,
  },
  props: {
    vaccination: {
      type: Array | Object,
      required: true,
    },
  },
  data: function () {
    return {
      closed: false,
    };
  },
};
</script>

<style scoped>
.collapse-link{
        cursor: pointer;
        font-size: 12px;
        margin-top: 10px;
        
    }
.card-header h4 {
    text-wrap: balance;
}

.pre-line {
  white-space: pre-line;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.active::after {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.dropdown-toggle:not(.dropdown-toggle-empty)::after {
    padding-right: 14px;
}

</style>
