<template>
<div>
    <div class="containerx space-top-1">
      <!-- Nav Scroller -->
      	<div id="pageHeaderTabParent">
			<div class="js-nav-scroller hs-nav-scroller-horizontal bg-white">
				<span class="hs-nav-scroller-arrow-prev" style="display: none;">
					<a class="hs-nav-scroller-arrow-link" href="javascript:;">
						<i class="fas fa-angle-left"></i>
					</a>
				</span>

				<span class="hs-nav-scroller-arrow-next" style="display: none;">
					<a class="hs-nav-scroller-arrow-link" href="javascript:;">
						<i class="fas fa-angle-right"></i>
					</a>
				</span>
			</div>
			<!-- Nav -->
			<ul class="js-scroll-nav nav nav-tabs page-header-tabs bg-white" id="pageHeaderTab" role="tablist">
				
				<li class="nav-item">
					<a class="nav-link active" data-toggle="tab" href="#summary-section" role="tab" aria-controls="about" aria-selected="true">
						<span v-if="user.foster_view_medical">Summary</span>
						<span v-else>Weight</span>
					</a>
				</li>
				<template v-if="user.foster_view_medical">
					<li class="nav-item" v-if="animal && animal.location_status == 'Foster'">
						<a class="nav-link" data-toggle="tab" href="#medications-section" role="tab" aria-controls="medications" aria-selected="true">
							Medications
							<span v-if="animal && animal.medicalMedications.length > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{animal.medicalMedications.length}}</span>
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#vaccinations-section" role="tab" aria-controls="medical" aria-selected="true">Vaccinations
						<span v-if="animal && animal.medicalVaccinations.length > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{animal.medicalVaccinations.length}}</span>
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#diagnostics-section" role="tab" aria-controls="diagnostics" aria-selected="true">Diagnostics
						<span v-if="animal && animal.medicalDiagnostics.length > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{animal.medicalDiagnostics.length}}</span>
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#surgeries-section" role="tab" aria-controls="surgeries" aria-selected="true">Surgeries
						<span v-if="animal && animal.medicalSurgeries.length > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{animal.medicalSurgeries.length}}</span>
						</a>
					</li>
				</template>
				<li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#exams-section" role="tab" aria-controls="exams" aria-selected="true" >
						Exams
					<span v-if="animal && animal.medicalExams.length > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{animal.medicalExams.length}}</span>
					</a>
				</li>
			</ul>
			<!-- End Nav -->
      	</div>
      <!-- End Nav Scroller -->
    </div>
</div>
</template>

<script>
import HsNavScroller from '../../../assets/vendor/hs-nav-scroller/dist/hs-nav-scroller.min.js'
import HSScrollNav from '../../../assets/vendor/hs-scroll-nav/dist/hs-scroll-nav.min.js'
import AnimalProfileMedical from './AnimalProfileMedical'
import { mapState } from 'vuex'

export default {
    name: 'MedicalHeader',
    components: {AnimalProfileMedical},
    directives: {},
    data() {
        return {}
    },
    mounted() {
        this.navScroller()
    },
	computed: {
		...mapState(['user', 'animal']),
		activeRequests(){
			return this.animal.requests.filter(request => request.status == 'submitted' || request.status == 'pending' || request.status == 'approved' || request.status == 'paid' || request.status == 'adopted'   );
		}
	},
    methods: {
		getWindowSizeForPhotos(){
			console.log('Set window size flag')
			this.$store.commit('SET_WINDOW_SIZE_FLAG', true)
			this.$emit("getWindowSize")
		},
        navScroller() {
            $(document).on('ready', function () {
                // INITIALIZATION OF NAV SCROLLER
                // =======================================================
                $('.js-nav-scroller').each(function () {
                    new HsNavScroller($(this)).init()
                })

                // INITIALIZATION OF SCROLL NAV
                // =======================================================
                $('.js-scroll-nav').each(function () {
                    var scrollNav = new HSScrollNav($(this)).init()
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>