var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr',{staticClass:"hr-line-solid"}),_vm._m(0),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-lg-12"},[_c('h6',[_vm._v("Follow-up Notes:")]),_c('p',[_vm._v(_vm._s(_vm.surgery.followup_notes))])])]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.surgery.schedule),function(followup,index){return (followup.type === 'surgery_followup')?_c('tr',{key:followup.id},[_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(followup.date)))]),(followup.status === 'pending' && _vm.pastDue(followup))?_c('td',{staticClass:"text-capitalize"},[_c('span',{staticClass:"badge badge-danger"},[_vm._v("Past Due")])]):_c('td',{staticClass:"text-capitalize"},[_c('span',{staticClass:"badge",class:{
                    'badge-primary': followup.status === 'pending',
                    'badge-info': followup.status === 'completed',
                    'badge-danger': followup.status === 'past due',
                    'badge-warning': followup.status === 'canceled'
                  }},[_vm._v(" "+_vm._s(followup.status)+" ")])]),_c('td',[_vm._v(_vm._s(followup.by || '-'))]),_c('td',[_vm._v(_vm._s(followup.notes || '-'))])]):_vm._e()}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('strong',[_vm._v("Surgery Follow-ups:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("By")]),_c('th',[_vm._v("Notes")])])])
}]

export { render, staticRenderFns }