<template>
<div>
     <ValidationProvider :vid="item.name" :rules="isRequired" v-slot="v">
        <div class="row form-group">
            <label class="col-form-label control-label" :class="[labelSize, {'text-danger' : v.errors[0] || error}]" >{{label}}
                <span v-if="!item.required" class="input-label-secondary">(Optional)</span>
            </label>
            <div :class="inputSize" >
                <date-picker 
                    v-model="date" 
                    @dp-change="handleChange" 
                    :config="datePickerOptions" 
                    :disabled="disabled"
                />
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
            </div>
        </div>
    </ValidationProvider>
</div>
</template>

<script>
    import datePicker from 'vue-bootstrap-datetimepicker';  
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import { ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        components : {ValidationProvider, datePicker},
        props : {
            id : {
                type : String,
                default : "date_"
            },
            item :{
                type : Object,
                required : true,
            },
            value : {
                type : [String, Date],
                required :false,
            },
            error : {
                type : String,
                required : false,
                default : null
            },
            labelSize: {
                type : String,
                default : 'col-sm-3'
            },
            inputSize: {
                type : String,
                default : 'col-sm-9'
            },
            disabled : {
                type : String,
                default : null
            }
        },
        data () {
            return {
                date : this.value ? this.value : new Date(), // this.value ? this.value : null,
                label : this.item.label,
                datePickerOptions: {
                    format: 'YYYY-MM-DD',
                    useCurrent: true,
                    defaultDate : new Date(),
                },    
            }
        },
        created(){
            
        },
        watch: { 
            value: function(newVal, oldVal) { // watch it
                this.date = newVal;
            }
        },
        computed : {
            isRequired(){
                return this.item.required == true ? 'required' : ''
            },
        },
        methods: {
            handleChange (value) {
        		this.$emit('input', this.date);  //moment(this.date, ['MM-DD-YYYY']).local().valueOf()/1000 ); //this.date);
        	},
            handleClick () {
                this.$emit('cleanError', this.item.name);               
            },
        }
    }
</script>