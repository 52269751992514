<template>
  <div class="overlayx">
        <div class="modalx">
            <div class="modal__titlex">
                <span>Session Expiration Warning</span>
            </div>
            <div class="p-3">
                <p>You have left this browser idle for 60 minutes and will be logged out in {{time/1000}} second<span v-if="time > 1000">s</span>.</p>
            </div>
        </div>
  </div>
</template>

<script>
import { authComputed } from '../store/helpers.js'

export default {
    data() {
        return {
            time: 10000,
            secondsLeft : 10
        };
    },
    computed: {
        ...authComputed,
        second() {
            return this.time / 1000;
        },
    },
    created() {
        if (this.isLoggedIn){
            let timerId = setInterval(() => {
                this.time -= 1000;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.time < 1) {
                    clearInterval(timerId);
                    this.$store.dispatch('logout')
                    .then(res => {
                        Vue.idle.stop()
                        this.$router.push('/login')
                    })
                    .catch(error => {
                        console.log('error', error)
                    })
                
                }
            }, 1000);
        }
    },
};
</script>

<style lang="postcss" scoped>
.overlayx {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px solid white
}

.modalx {
  width: 500px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
  padding: 5px;
  background-color: white
}

.modal__titlex {
  color: #38404f;
  @apply flex items-center justify-between p-3 font-bold;
}
</style>