import Vue from "vue"
import moment from 'moment'

Vue.filter('formatPhoneNumber', (value) => {
    // Returns phone number in the format (123)456-7890
    // Appends 1 if passed in
    // TODO: how to format international numbers with more than 11 digits

    if (!value){
        return;
    }
    
    let cleaned = ('' + value).replace(/\D/g, '');
    let match;

    if (value.length === 7) {
        match = cleaned.match(/^(\d{3})(\d{4})$/);
    }else if (value.length === 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    } else {
        match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    }

    if (match && value.length === 7) {
        return `${match[1]}-${match[2]}`;
    }else if (match && value.length === 10) {
        return `(${ match[1]}) ${match[2]}-${match[3]}`;
    } else if (match && value.length !== 10) {
        return `${ match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return value;
});

var truncate = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', truncate);

Vue.filter('formatDate', function(value){
    if (value && value.$date) {
        //console.log('filter', value );
        var date = value.$date.$numberLong.valueOf().toString();
       // console.log('filter $date: ', date, moment(date, ['x']).format('MM-DD-YYYY'))
        return moment(date, ['x']).format('MM-DD-YYYY')
    }
    else if (Number.isInteger(value) )
    {
        //console.log('filter value is int',value)
        //console.log(moment(value*1000).format('MM-DD-YYYY') )
        return moment(value*1000).format('MM-DD-YYYY')
    }
    else if (value == '-'){
        return ' - ';
    }
    else if (value)
    {
        //console.log('filter non object',value)
        return moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM-DD-YYYY')
    }
    
}); 

Vue.filter('formatDateTime', function(value){
   if (value)
    {
        return moment(value, ['MM-DD-YYYY h:mm:ss A', 'YYYY-MM-DD h:mm:ss A']).format('MM-DD-YYYY h:mm:ss A')
    }
}); 

Vue.filter('displayArray', function(value){
    if ( Array.isArray(value) ) {
        return value.join(', ');
    }
    return value;
});

Vue.filter('isDate', function(value){
    if( value && value.$date){
        if (value && value.$date) {
            //console.log('filter', value );
            var date = value.$date.$numberLong.valueOf().toString();
           // console.log('filter $date: ', date, moment(date, ['x']).format('MM-DD-YYYY'))
            return moment(date, ['x']).format('MM-DD-YYYY')
        }
        else if (Number.isInteger(value) )
        {
            //console.log('filter value is int',value)
            //console.log(moment(value*1000).format('MM-DD-YYYY') )
            return moment(value*1000).format('MM-DD-YYYY')
        }
        else if (value)
        {
            //console.log('filter non object',value)
            return moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM-DD-YYYY')
        }
    }else{
        return value
    }
}); 

Vue.filter('formatSize', function (size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size > 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size > 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size > 1024) {
      return (size / 1024).toFixed(2) + ' KB'
    }
    return size.toString() + ' B'
  })