<template>
    <div>
        <div v-if="birthday">
            <div v-if="estimated">
                <h5 >Est. Age</h5>
                <p>{{calculatedAge}}</p>
            </div>
            <div v-else>
                <h5>Birthday</h5>
                <p>{{formatedBirthday}}</p>
            </div>
        </div>
        <div v-else>
            <h5>Birthday</h5>
            <p>---</p>
        </div>
    </div>
</template>

<script>

import moment from 'moment'

export default {
    name: 'BaseBirthdayField',
    components: {  },
    directives: {  },
    props : {

        field: {
            type : Object,
            required :true
        },
        birthday : {
            type : String,
            default : null
        },   
        estimated : {
            type : Boolean,
            default : true
        }   
    },
    data() {
        return {
            
    
        };
    },
    mounted() {
        
    },
    computed :{
        calculatedAge(){
            var a = moment();
            var b = moment(this.birthday);
            var age = moment.duration(a.diff(b));
            var years = age.years();
            var months = age.months();
            return years + " years " + months + " months ";
        }, 
        formatedBirthday(){
            return moment(this.birthday).format('MMM Do, YYYY')
        }
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>