<template>
     <ValidationProvider :vid="item.name" :rules="isRequired" v-slot="v">
        <div class="row form-group">
            <label class="col-form-label control-label" :class="[labelSize, {'text-danger' : v.errors[0]}]">
                {{item.label}} 
                <span v-if="!item.required" class="input-label-secondary">(Optional)</span>
            </label>
            <div :class="inputSize" @click="handleClick">
                <select 
                    class="form-control"
                    :class="{'is-invalid' : v.errors[0]}"
                    :name="item.name"
                    :multiple="item.multiple"
                    :value="value"
                    @change="$emit('input', $event.target.value)"
                    :disabled="disabled"
                >
                    <option value="" disabled>Please Select</option>
                    <option v-for="val in item.values" :value="val" :key="val" >{{val}}</option>
                </select>
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>

    import { ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        components : {
            ValidationProvider
        },
        props : {
            id : {
                type : String,
                default : "select_"
            },
            item :{
                type : Object,
                required : true,
            },
            value : {
                type : String,
                required :false,
            },
            error : {
                type : String,
                required : false,
                default : null
            },
            labelSize: {
                type : String,
                default : 'col-sm-3'
            },
            inputSize: {
                type : String,
                default : 'col-sm-9'
            },
            disabled : {
                type : String,
                default : null
            }
        },
        data () {
            return {

            }
        },
        computed :{
            isRequired(){
                return this.item.required == true ? 'required' : ''
            },
        },
        methods: {
            handleClick () {
                this.$emit('cleanError', this.item.name);               
            }
        }
    }
</script>

<style scoped>
   .input-group.select .form-control{
        height: auto;
   }
</style>