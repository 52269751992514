<template>
  <div
    :id="'microchip_edit_modal_' + animal_id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="id"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Edit Microchip Info</h3>
          <button
            ref="CloseBtn"
            type="button"
            class="btn btn-xs btn-icon btn-soft-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            <svg
              aria-hidden="true"
              width="10"
              height="10"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
              />
            </svg>
          </button>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="modal-body">
              <BaseEditMicrochipField
                v-model="values.microchip"
                :item="{ label: 'Microchip #', required: true }"
              />
              <BaseEditSelectField
                v-model="values.microchip_issuer"
                :item="{ label: 'Issuer', required: true, values: issuers }"
              />
              <p v-if="error" class="text-danger">{{ error }}</p>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-white btn-sm" data-dismiss="modal">
                Cancel
              </button>
              <button type="subumit" class="btn btn-primary btn-sm">
                Save
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { MICROCHIP_ISSUERS } from '../../constants'

export default {
  name: 'EditMicrochipModal',
  components: { ValidationObserver },
  directives: {},
  props: {
    animal_id: {
      type: String,
    },
    microchip: {
      type: String,
    },
    microchipIssuer: {
      type: String,
    },
  },
  data() {
    return {
      values: {
        microchip: this.microchip,
        microchip_issuer: this.microchipIssuer,
      },
      error: null,
      issuers: MICROCHIP_ISSUERS,
    }
  },
  mounted() {},
  computed: {},
  methods: {
    onSubmit() {
      if (this.animal_id && (this.microchip !== this.values.microchip ||
        this.microchipIssuer !== this.values.microchip_issuer)) {
        this.error = null

        this.$store
          .dispatch('updateAnimal', { animal_id: this.animal_id, data: this.values,old_data:this.values.microchip })
          .then((response) => this.$refs.CloseBtn.click())
          .catch((err) => {
            console.log(err)
            this.error = err.response.data
          })
      } else {
        this.$refs.CloseBtn.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
