<template>
  <div class="login">
    <main id="content" role="main">
      <!-- Login Form -->
      <div class="container space-2 space-lg-3">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="w-md-75 w-lg-50 mx-md-auto" @submit.prevent="handleSubmit(login)">
            <!-- Title -->
            <div class="mb-5 mb-md-7">
              <h1 class="h2 mb-0">Animals First - Foster App</h1>
              <p>Login to manage your fosters.</p>
            </div>
            <!-- End Title -->

            <!-- Form Group -->
            <ValidationProvider rules="email|required|min:6" v-slot="v">
              <div class="js-form-message form-group">
                <label class="input-label" for="signinSrEmail">Email address</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  name="email"
                  id="signinSrEmail"
                  placeholder="Email address"
                  aria-label="Email address"
                />
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
              </div>
            </ValidationProvider>
            
            <!-- End Form Group -->

            <!-- Form Group -->

            <ValidationProvider rules="required|min:6" v-slot="v">
              <div class="js-form-message form-group">
                <label class="input-label" for="signinSrPassword">
                  <span class="d-flex justify-content-between align-items-center">
                    Password
                    <router-link to="/forgot" class="link-underline text-capitalize font-weight-normal">
                      Forgot Password?
                    </router-link>
                  </span>
                </label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  name="password"
                  id="signinSrPassword"
                  placeholder="********"
                  aria-label="********"
                />
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
              </div>
            </ValidationProvider>
            <!-- End Form Group -->
            <p v-if="error" class="text-danger">{{ error }}</p>
            <!-- Button -->
            <div class="row align-items-center mb-5">
              <div class="col-sm-6 mb-3 mb-sm-0">
                <span class="font-size-1 text-muted">Don't have an account?</span>
                <router-link to="/register" name="register" class="ml-1">
                  <a class="font-size-1 font-weight-bold" href>Signup</a>
                </router-link>
              </div>

              <div class="col-sm-6 text-sm-right">
                <button type="submit" class="btn btn-primary transition-3d-hover">Get Started</button>
              </div>
            </div>
            <!-- End Button -->
          </form>
        </ValidationObserver>
      </div>
      <!-- End Login Form -->
    </main>
  </div>
</template>

<script>

export default {
	props: {},
	components: {},
	data() {
		return {
			email: '',
			password: '',
        inviteCode: this.$route.query.invite_code || '',
			error: null,
		}
	},
	methods: {
		login() {
			this.error = null
			let data = {
				email: this.email,
				password: this.password,
			}

			this.$store.dispatch('login', data)
				.then((response) => {
					let user = response.data.user
					if (this.inviteCode) {
						this.$store
							.dispatch('checkInvite', this.inviteCode)
							.then((resp2) => {
								console.log('invite', resp2)
								this.$router.push('/invites')
							})
							.catch((err) => {
								console.log('invite error', err)
								this.navigateToNextPage(user)
							})
					} else {
						this.navigateToNextPage(user)
					}
				})
				.catch((err) => {
					console.log('login error', err.response)
					this.error = err.response.data
				})
		},
		navigateToNextPage(user) {
			if (user.shelters.length > 1) {
				//TODO 
				//if user belongs to more than shelter I need a way for the user to pick a shelter
				this.$router.push('/shelters')
			} else {
				let data = {
					shelter_id: user.shelters[0].shelter_id,
					people_id: user.shelters[0].people_id,
				}
				console.log('Shelter', data)
				this.$store
				.dispatch('shelter', data)
				.then((response) => {
					this.$router.push('/animals')
				})
				.catch((err) => {
					console.log('shelter error', err)
					this.error = err.response.data
				})
			}
		},
	},
}
</script>
