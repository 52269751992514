<template>
<div>
    <img class="icon" :src="photoSrc" />
    <div class="icon-delete-btn" v-if="withButton">
        <font-awesome-icon class="close" icon="times" @click="remove" /> 
    </div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '../../../store/helpers.js'


export default {
    name: 'Photo',
    props: {
        index: {
            type: Number
        },
        withButton: {
            type: Boolean,
            default: false
        },
        photo: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        ...mapState(['user', 'animal']),
        ...authComputed,
        photoSrc() {
            return `${this.animal.image_url}${this.photo.filename}`
        }
    },
    methods: {
        remove () {
            this.$emit('remove', {
                index: this.index
            })
        }
    }
}
</script>

<style scoped>
    @keyframes shake {
        from {
            transform: rotate(-4deg);
        }
        to {
            transform: rotate(4deg);
        }
    }
    .icon {
        position: relative;
        background-color: transparent;
        margin: 14px;
        height: 52px;
        width: 52px;
        border-radius: 10px;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.07);
        color: #777;
        font-weight: 900;
        font-size: 12px;
        line-height: 52px;
        text-align: center;
        /*transition: all 0.3s;*/
        cursor: pointer;
        z-index: -1;
    }
    .icon-delete-btn .close {
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 7px;
        right: 8px;
        top: 8px;
        color : #111;
        border: 1px solid rgba(15, 15, 15, 0.4);
        background: #fff;
        cursor: pointer;
    }

    .v-grid-item-dragging .icon {
        animation-name: shake;
        animation-duration: 0.07s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
</style>