<template>
<div>   
    <div class="col-lg-12">
        <div v-for=" field, index in form.fields" :key="index">
            <div v-if="field.customField || field.medicalField">
                <BaseHeader
                    v-if="field.type == 'header'"
                    :item="field"
                    headerSize="h2"
                    classSize="col-lg-12"
                />
                <BaseSeperator
                    v-if="field.type == 'seperator'"
                    :classSize="'col-lg-12'"
                />
                <BaseParagraph
                    v-if="field.type == 'paragraph'"
                    classSize="col-lg-12"
                    :item="field"
                />
                <BaseEditTextField
                    v-if="field.type == 'text' || field.type == 'number' || field.type == 'email'"
                    v-model="exam[field.name]"
                    :item="field"
                    :labelSize="labelSize"
                    :inputSize="inputSize"
                />
                <BaseEditSelectField
                    v-if="field.type == 'select'"
                    v-model="exam[field.name]"
                    :item="field"
                    :labelSize="labelSize"
                    :inputSize="inputSize"
                />
                <BaseEditCheckboxField
                    v-if="field.type == 'checkbox'"
                    :key="'checkbox_'+index"
                    v-model="exam[field.name]"
                    :item="field"
                    :labelSize="labelSize"
                    :inputSize="inputSize"
                />
                <BaseEditRadioField
                    v-if="field.type == 'radio'"
                    v-model="exam[field.name]"
                    :item="field"
                    :labelSize="labelSize"
                    :inputSize="inputSize"
                />
                <BaseEditTextareaField
                    v-if="field.type == 'textarea'"
                    v-model="exam[field.name]"
                    :item="field"
                    :labelSize="labelSize"
                    :inputSize="inputSize"
                />
                <BaseEditDatePickerField
                    v-if="field.type == 'date' || field.type == 'birthday'"
                    v-model="exam[field.name]"
                    :item="field"
                    :labelSize="labelSize"
                    :inputSize="inputSize"
                />
            </div>
        </div>
    </div>
</div>
</template>


<script>

    export default {
        components : {
        },
        props : {
            form : {
                type : Object,
                required :true,
            }, 
            exam : {
                type : Object,
                required : true
            }
        },

        data () {
            return {
                
            	labelSize : 'col-lg-12',
			    inputSize : 'col-lg-12',
            	options: {
                    format: 'MM/DD/YYYY',
                    useCurrent: true,
                },
                date : this.item ? this.item : new Date(),
                key : 0,
            }
        },
        computed: {

        },
        mounted(){
            
        },
        methods: {
        	
        }
    }
</script>

<style scoped>


</style>