<template>
<div>
    <div :id="id" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="id" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="id">{{title}}</h5>
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                        <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                        </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <h3 v-if="action == 'approve'">Are you sure you would like to approve {{adopter.first_name}} to adopt {{animal.animal_name}}?</h3>
                    <h3 v-if="action == 'decline'">Are you sure you would like to decline {{adopter.first_name}}?</h3>
                    <h3 v-if="action == 'undecline'">Are you sure you would like to undecline {{adopter.first_name}}?</h3>
                     <h3 v-if="action == 'unapprove'">Are you sure you would like to unapprove {{adopter.first_name}}?</h3>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn text-capitalize" :class="color" @click="onSubmit(action)">{{action}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ApproveDenyModal',
    components: {  },
    directives: {  },
    props: {
		id: {
			type: String,
			required: true,
		},
        title: {
			type: String,
			required: true,
		},
        action: {
			type: String,
			required: true,
		},
        color: {
			type: String,
			required: true,
		},
        adopter: {
			type: Object,
			required: true,
		},
        request: {
            type : Object,
            required : true
        },
        animal: {
            type : Object,
            required : true
        },
        index: {
			type: String,
			required: true,
		},
	},
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    computed :{
        ...mapState(['user']),
    },
    methods: {
        onSubmit(action){

            let data = {}
            data.animal = {}
            data.adopter = {}

            data.request_id = this.request._id
            data.shelter_id = this.animal.shelter_id
            data.animal_id = this.animal._id
            
            data.animal._id = this.animal._id
            data.animal.animal_name = this.animal.animal_name
            data.animal.shelter_id = this.animal.shelter_id
            data.adopter._id = this.adopter._id
            data.adopter.first_name = this.adopter.first_name
            data.adopter.last_name = this.adopter.last_name
            data.adopter.email = this.adopter.email

            if (action == "decline"){
                console.log('request', action)
                this.$store.dispatch('declineRequest', data)
                .then(response => {
                    $("#"+this.id).modal("hide");
                })
                .catch(error => {
                    console.log('error', error)
                })
            }
            else if (action == "approve"){
                this.$store.dispatch('approveRequest', data)
                .then(response => {
                    $("#"+this.id).modal("hide");
                })
                .catch(error => {
                    console.log('error', error)
                })
            }
            else if (action == "undecline"){
                this.$store.dispatch('undeclineRequest', data)
                .then(response => {
                    $("#"+this.id).modal("hide");
                })
                .catch(error => {
                    console.log('error', error)
                })
            }
            else if (action == "unapprove"){
                this.$store.dispatch('unapproveRequest', data)
                .then(response => {
                    $("#"+this.id).modal("hide");
                })
                .catch(error => {
                    console.log('error', error)
                })
            }
        }
    },
};
</script>

<style lang="scss" scoped>

</style>