<template>
  <div>
    <hr class="hr-line-solid">
    <div class="row">
      <div class="col-lg-12">
        <strong>Surgery Follow-ups:</strong>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-12">
        <h6>Follow-up Notes:</h6>
        <p>{{ surgery.followup_notes }}</p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>By</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="followup, index in surgery.schedule" :key="followup.id" v-if="followup.type === 'surgery_followup'">
                <td>{{ followup.date | formatDate }}</td>
                <td
                  class="text-capitalize"
                  v-if="followup.status === 'pending' && pastDue(followup)"
                >
                  <span class="badge badge-danger">Past Due</span>
                </td>
                <td v-else class="text-capitalize">
                  <span
                    class="badge"
                    :class="{
                      'badge-primary': followup.status === 'pending',
                      'badge-info': followup.status === 'completed',
                      'badge-danger': followup.status === 'past due',
                      'badge-warning': followup.status === 'canceled'
                    }"
                  >
                    {{ followup.status }}
                  </span>
                </td>
                <td>{{ followup.by || '-' }}</td>
                <td>{{ followup.notes || '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
      name: 'medical_surgery_followup_dates',
      components: {
      },
        props : {
          surgery : {
              type : Array|Object,
              required :true,
          },

      },
      methods : {
          pastDue (result){
              return moment.unix(result.date/1000).endOf('day').unix() < moment().unix();
          },
      }
  } 

</script>
<style scoped>

.tableRowColor {
    background-color: #f9f9f9;
}
.table-responsive {
     min-height: .01% !important;
}

</style>
        
