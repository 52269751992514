<template>
<div>
    <div class="container space-top-1">
		<div class="row">
			<div class="col-lg-12 mb-7 mb-lg-3">
				<div>
					<div class="mb-lg-3">
						<div class="card-header">
							<div class="d-flex w-100">
								<div class="w-100">
									<button v-if="!addActivity" class="btn btn-xs float-right btn-success" @click="addNote()">+ Note</button>
								</div>
							</div>
						</div>
						<div v-show="addActivity">
							<div class="card-body">
								<tinymce id="d1" v-model="content" :other_options="other_options"></tinymce>
								<button class="btn btn-xs pull-right btn-primary m-1 mt-2" @click="submit">Submit</button>
								<button class="btn btn-xs pull-right btn-default mt-2" @click="addActivity = !addActivity">Cancel</button>
							</div>
						</div>
					</div>
				</div>
				<!-- Notes -->
				<ul class="list-unstyled list-lg-article">
					<li v-for="(item, index) in animal.animalNotes" :key="index">
						<div class="media">
							<div class="media-body">
								<div class="d-inline-flex justify-content-center align-items-center">
									<div class="avatar avatar-circle mr-1 d-inline-flex justify-content-center align-items-center">
										<font-awesome-icon
										class="list-group-icon"
										icon="user"
										/>
									</div>
									<div class="d-inline-block ">
										<h6 class="mb-0" v-if="item.user_id==user._id">You</h6>
										<h6 class="mb-0" v-else>{{ item.user_name }}</h6>
										<small class="d-block">{{ item.updated_date | formatDateTime }}</small>
									</div>
								</div>
								<div v-if="edit && edit_id==item._id">
									<tinymce ref="notes" :id="'note_'+index" v-model="content" :other_options="other_options" @editorInit="editorInit(item)"></tinymce>
									<button class="btn btn-xs pull-right btn-primary ml-1  mt-2" @click="update(item)">Save</button>
									<button class="btn btn-xs pull-right btn-danger ml-1  mt-2" @click="deleteNote(item)">Delete</button>
									<button class="btn btn-xs pull-right btn-default ml-1  mt-2" @click="edit = !edit">Cancel</button>
								</div>
								<div v-else>
									<div @click="editNote(item)" :class="{ 'notes': item.user_id === user._id }" class="transparent-border blockquote">
										<p v-html="item.description"></p>
									</div>
									<div v-if="showComment && edit_id==item._id">
										<input type="text" placeholder="Write a comment.." class="form-control mb-2" v-model="content">
										<span class="" @click="showComment = !showComment"> <button class="btn btn-xs btn-default">Cancel</button> </span>
										<span class="" @click="postComment(item)"> <button class="btn btn-xs btn-primary">Save</button> </span>
									</div>
									<div v-else class="btn-group">
										<button class="btn btn-white btn-xs mb-2" @click="addComment(item)"><font-awesome-icon class="list-group-icon" icon="comments"/></button>
									</div>
									<div v-show="item.comments" class="m-l-md">
										<div v-for="(comment, index) in item.comments" :key="index" >
											<comments 
												v-if="comment.status == 'active'"
												:item="item" 
												:comment="comment" 
												:index="index"
											>
											</comments>
										</div>
									</div> 
								</div>
							</div>
						</div>
						<hr>
					</li>
				</ul>
			</div>
			<!-- End Row -->
		</div>
		<!-- Notes Section -->
	</div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import comments from './comments'
import { authComputed } from '../../../store/helpers.js'
export default {
	name: 'AnimalProfileNotes',
	components: {comments},
	data() {
		return {
			addActivity: false,
      		content: "",
			edit:false,
			edit_id:'',
			showComment: false,
			showUserIcon: [],
			error: '',
			other_options : {
                    height : '200',
                    forced_root_block : false,
                    menu: {
                        //file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
                        edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                        //view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
                        insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
                        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat' },
                        //tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                        //table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                        //help: { title: 'Help', items: 'help' }
                    },
                },
		}
	},
	computed: {
		...authComputed,
		...mapState(['user', 'animal']),
	},
	methods: {
		handleImageError(index) {
			console.log(`Error loading image for item at index ${index}`);
			this.$set(this.showUserIcon, index, true);
		},
		deleteNote(item){
			const data={
				id:item._id
			}
			const animal_id = this.animal._id;
			this.$store
			.dispatch('deleteNote', {data,animal_id})
			.then((response) => {
				this.edit= false;
			})
			.catch((err) => {
			console.log(err)
			this.error = err.response.data
			})
		},
		addNote(){
			this.content = ''
			this.addActivity = true;
		},
		postComment(item){
			const data = {
				id:item._id,
				comment:this.content,
				foster_id:this.user._id,
				user_id:null,
				user_name:this.user.fisrt_name+' '+this.user.last_name,
			};
			const animal_id = this.animal._id;
			this.$store
				.dispatch('addComment', {data,animal_id})
				.then((response) => {
					this.showComment= false;
					this.content = '';
				})
				.catch((err) => {
				console.log(err)
				this.error = err.response.data
			})
		},
		addComment(item){
			this.content = ''
			this.showComment =! this.showComment
			this.edit_id = item._id
		},
		update(item){
			const data = {
				id:item._id,
				content:this.content
			};
			const animal_id = this.animal._id;
			this.$store
			.dispatch('updateNotes', { data, animal_id })
			.then((response) => {
				this.edit= false;
				this.content=''
			})
			.catch((err) => {
			console.log(err)
			this.error = err.response.data
			})
		},
		editNote(item){
			if(item.user_id === this.user._id){
				console.log('this.content', item.description)
				this.edit = true;
				this.edit_id=item._id
				//this.content = 'test test' //item.description;
			}
		},
		editorInit(item){
			console.log('this.content 2', item.description)
			this.content = item.description;
		},
		submit(){
			this.error = null

			const data = {
				description:this.content,
				animal_id: this.animal._id,
				shelter_id:this.animal.shelter._id,
				foster_id:this.user._id,
				user_name:this.user.full_name,
				title:"Foster Note",
				category:"foster",
				assigned_to : null,
				status : "active",
				type : "note"
			}

			this.$store
				.dispatch('addNotes', data)
				.then((response) => {
					this.addActivity= false;
					this.content=''
				})
				.catch((err) => {
				console.log(err)
				this.error = err.response.data
			})
		}
	},
}
</script>

<style lang="scss" scoped>
li{
	font:menu;
	.notes{
		&:hover{
			outline:1px solid rgb(197, 195, 195);
			cursor: pointer;
		}
	}
	.blockquote{
		margin: 0px !important;
		font-size: 1rem;
    	border-left: 0.1875rem solid #e7eaf3;
	}
}
.card-body{
	border-bottom: 0.0625rem solid #e7eaf3;
}


</style>