<template>
    <div>
        <div :id="id" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="id" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-danger" :id="id">{{title}}</h5>
                        <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h3 class="text-danger">Are you sure you would like to delete the video?</h3>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger"  @click="deleteVideo()">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
        
    export default {
        name: 'DeleteVideoModal',
        components: {  },
        directives: {  },
        props: {
            id: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            action: {
                type: String,
                required: true,
            },
            color: {
                type: String,
                default: 'primary',
            },
            video_url : {
                type: String,
                required : true
            },
            animal : {
                type : Object,
                required : true
            }
        },
        data() {
            return {
                
            };
        },
        mounted() {
            
        },
        computed :{
        },
        methods: {
            async deleteVideo(){
               this.$store.dispatch('deleteVideo', {animal_id: this.animal._id, video_url: this.video_url})
                .then(response => {
                    this.showToaster('success', 'Video Removed Successfull')
                    this.$emit('updateDetails')
                    $("#"+this.id).modal("hide");
                }).
                catch(errro => {
                    this.showToaster('error', 'There was an error removing the video')
                })
            },
        }
    };
    </script>
    
    <style lang="scss" scoped>
    
    </style>