<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="collapse multi-collapse" :id="id">
                    <hr>
                    <div class="card card-body shadow-none">
                        <div v-for="app in application.application" :key="app.id">
                            <div v-if="app.type == 'header'">
                                <h3><strong>{{app.question}}</strong></h3>
                            </div>
                            <div v-else-if="app.type == 'paragraph'">
                                <p><i><strong>{{app.text}}</strong></i></p>
                                <hr>
                            </div>
                            <div v-else-if="app.type == 'seperator'">
                                
                            </div>
                            <div v-else-if="app.type == 'checkbox'">
                                <h5>{{app.question}}:</h5>
                                <p class="mb-4 ml-3">{{app.answer.join(', ')}}</p>
                                <hr>
                            </div>
                            <div v-else>
                                <h5>{{app.question}}:</h5>
                                <p class="mb-4 ml-3">{{app.answer}}</p>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'AdopterApplication',
    components: {  },
    directives: {  },
    props: {
		application: {
			type: Object,
			required: true,
		},
        id: {
			type: String,
			required: true,
		},
	},
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>