import Vue from 'vue'
import VueRouter from 'vue-router'
import Animals from '../views/Animals.vue'
import AnimalTable from '../components/Animals.vue'
import AnimalProfileView from '../views/AnimalProfile.vue'
import AnimalProfile from '../components/AnimalProfile/AnimalProfile.vue'
import AvailableAnimals from '../views/AvailableAnimals.vue'
import AvailableAnimalsTable from '../components/AvailableAnimals.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Invites from '../views/Invites.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/login',
    name: 'login 1',
    component: Login,
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotPassword,
  },
  {
    path: '/passwordReset',
    name: 'passwordReset',
    component: ResetPassword,
  },
  {
    path: '/invites',
    name: 'invites',
    component: Invites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/animals',
    component: Animals,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        component: AnimalTable,
        name: 'animals',
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/animal-profile/:animal_id',
    component: AnimalProfileView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        component: AnimalProfile,
        name: 'animalProfile',
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: '/available-animals',
    component: AvailableAnimals,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        component: AvailableAnimalsTable,
        name: 'availableAnimals',
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('user')
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    next('/login')
  }else{
    next()
  }
})

export default router
