<template>
    <div>
        <hr>
        <div class="row">
            <div class="col-lg-12">
                <strong>Test Results:</strong>
            </div>
        </div>
        <div class="row m-b-xxs">
            <div class="col-lg-12">
                <div class="table-responsive tableFixHead" >
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Label</th>
                                <th>Status</th>
                                <th>Results</th>
                                <th>By</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="result, index in diagnostic.diagnostic_results" :key="index">
                                <td>{{result.id}}</td>
                                <td>{{result.date | formatDate}}</td>
                                <td>{{result.label}}</td>
                                <td class="text-capitalize" v-if="result.status=='pending' && pastDue(result)">
                                    <span class="badge badge-danger">
                                        Past Due
                                    </span>
                                </td>
                                <td v-else class="text-capitalize">
                                    <span class="badge"
                                        :class="
                                        [ {'badge-primary ' : result.status == 'pending'},
                                            {'badge-info' : result.status == 'completed'},
                                            {'badge-warning' : result.status == 'skipped'},
                                            {'badge-danger' : result.status == 'past due'},
                                            {'badge-warning' : result.status == 'canceled'},
                                        ]">
                                        {{result.status}}
                                    </span>
                                </td>
                                <td v-if="result.result" class="text-capitalize">{{result.result}}</td>
                                <td v-else> - </td>
                                <td v-if="result.by">{{result.by}}</td>
                                <td v-else> - </td>
                                <td v-if="result.notes">{{result.notes}}</td>
                                <td v-else> - </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import moment from 'moment'

export default {
    name: 'diagnostic_result',
    props : {
        diagnostic : {
            type : Array|Object,
            required :true,
        },
    },
    data: function () {
        return { 
        }
    },
    methods : {
        pastDue (result){
            return moment.unix(result.date/1000).endOf('day').unix() < moment().unix();
        },
    }
} 
</script>