<template>
<div>
	<div class="container space-top-2">
		<div class="row align-items-center mb-5">
			<div class="col-sm mb-3 mb-sm-0">
				<h3 class="h3 mb-0">Exams</h3>
			</div>
			<div class="col-sm-auto">
				<div class="btn-group">
					<button class="btn btn-primary btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						+ Medical Forms
					</button>
					<div class="dropdown-menu">
						<div v-for="form, index in animal.medicalForms" :key=index>
							<button class="dropdown-item" type="button"
								data-toggle="modal" 
								data-target="#medicalForm" 
								data-backdrop="static"
								@click="showModal(form._id)"
								>{{form.form_title}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="animal.medicalExams && animal.medicalExams.length > 0">
			<div v-for="(exam) in animal.medicalExams" :key="exam._id">
				<Exams :exam="exam"></Exams>
			</div>
		</div>
		<div v-else >
			<h3><span v-if="animal.animal_name">{{animal.animal_name}}</span><span v-else>Unknown</span> does not have any medical information.</h3>
		</div>
	</div>
	<AddExamModal 
        v-if="showFormModal"
		:id="'medicalForm'"
        :form_id="form_id"
        :key="'form'+formKey"
		:title="'Add Medical'"
		:animal="animal"
        @updateDetails="$emit('updateDetails')"
    > 
	</AddExamModal>
</div>
</template>

<script>

import { authComputed } from '../../../store/helpers.js'
import Exams from './Exams'
import AddExamModal from './AddExamModal'

export default {
	name: 'AnimalProfileMedical',
	components: {Exams, AddExamModal},
	directives: {},
	props: {
		animal: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			formKey : 1,
			form_id : null,
			showFormModal: false,	
		}
	},
	computed: {
        //...mapState(['user', 'animal']),
        ...authComputed,
	},
	mounted() {	},
	methods: {
		showModal(form_id){
			console.log('showModal', form_id)
            this.form_id = form_id
            this.formKey += 1;
            this.showFormModal = true;

        },
	},
}
</script>

<style lang="scss" scoped>

</style>