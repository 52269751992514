<template>
  <div class="login">
    <main id="content" role="main">
      <!-- Login Form -->
      <div class="container space-2 space-lg-3">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="w-md-75 w-lg-50 mx-md-auto" @submit.prevent="handleSubmit(submitRequest)">
            <!-- Title -->
            <div class="mb-5 mb-md-7">
              <h1 class="h2 mb-4">Animals First - Foster App</h1>
              <p class="text-danger" v-if="!token || !userId">
                Invalid password reset link.
              </p>
              <h3 :class="{ 'text-success': success }" v-else>
                {{ success ?
                  'Your password has been reset successfully.' :
                  'Please provide your new password.'
                }}
              </h3>
            </div>
            <!-- End Title -->

            <template v-if="token && userId">
              <!-- Form Group -->
              <ValidationProvider rules="required|min:6" v-slot="v" vid="password">
                <div class="js-form-message form-group" v-if="!success">
                  <label class="input-label" for="signinSrPassword">
                    <span class="d-flex justify-content-between align-items-center">
                      Password
                    </span>
                  </label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    name="password"
                    id="signinSrPassword"
                    placeholder="********"
                    aria-label="********"
                    data-msg="Your password is invalid. Please try again."
                  />
                  <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                </div>
              </ValidationProvider>
              <!-- End Form Group -->

              <!-- Form Group -->
              <ValidationProvider rules="required|confirmed:password" v-slot="v">
                <div class="js-form-message form-group" v-if="!success">
                  <label class="input-label" for="signinSrConfirmPassword">Confirm password</label>
                  <input
                    type="password"
                    class="form-control"
                    name="confirmPassword"
                    id="signinSrConfirmPassword"
                    placeholder="********"
                    aria-label="********"
                    v-model="password_confirmation"
                  />
                  <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                </div>
              </ValidationProvider>
              <!-- End Form Group -->

              <p v-if="error" class="text-danger">{{ error }}</p>
              <!-- Button -->
              <div class="row align-items-center mb-5">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <span class="">Go back to</span>
                  <router-link to="/login" class="ml-1">
                    <a class="font-size-1 font-weight-bold" href>Login</a>
                  </router-link>
                </div>

                <div class="col-sm-6 text-sm-right" v-if="!success">
                  <button type="submit" class="btn btn-primary transition-3d-hover">Submit</button>
                </div>
              </div>
              <!-- End Button -->
            </template>
          </form>
        </ValidationObserver>
      </div>
      <!-- End Login Form -->
    </main>
  </div>
</template>

<script>
export default {
	props: {},
	components: {},
	data() {
		return {
			password: '',
      password_confirmation: '',
      success: false,
      token: this.$route.query.token || '',
      userId: this.$route.query.id || '',
			error: null,
		}
	},
	methods: {
		submitRequest() {
      this.success = false;
      this.error = null;

			this.$store
				.dispatch('resetPassword', { userId: this.userId, token: this.token, password: this.password })
				.then((response) => {
					if (response.data.success) {
            this.success = true;
          } else {
            this.error = 'Failed to perform this operation. Please try again later.';
          }
				})
				.catch((err) => {
					console.log('password reset error', err.response)
					this.error = err.response
				})
		},
	},
}
</script>
