<template>
<div>
    <div v-if="isLoading">
	    <div class="container space-top-1">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
	</div>
    <div v-else>
        <div class="container space-top-1">
            <div class="row align-items-center">
                <div class="col-sm mb-3 mb-sm-0">
                <h1 class="h2 mb-0">{{total}} Animals Available for Foster</h1>
                </div>
            </div>
        </div>

        
        <!-- Animal Item -->
        <div class="container space-1 space-bottom-2 space-bottom-lg-3">
            <div  v-if="animals  && animals.length > 0">
                <div v-for="(animal, index) in animals" :key="index">
                    <div class="card card-bordered card-hover-shadow mb-5">
                        <div class="card-body">
                            <div class="pb-1 mb-1">
                                <div class="row">
                                <div
                                    class=" col-md-3 d-md-flex align-items-md-start flex-md-column mb-5 mb-md-0"
                                >
                                    <a class="mb-3">
                                    <img class="img-fluid w-100 rounded-lg" :src="animal.photo" alt="Animal Image" />
                                    </a>
                                </div>

                                <div class="col-md-8">
                                    <div class="row">
                                    <div class="col-sm-7">
                                        <h2 class="mb-1">
                                            <span v-if="animal.animal_name">{{animal.animal_name}}</span>
                                            <span v-else>Unknown</span>
                                        </h2>
                                    </div>
                                    <div class="col-sm-5 text-sm-right">
                                        <h3 class="mb-1">
                                        <a>${{animal.adoption_fee}}</a>
                                        </h3>
                                    </div>
                                    </div>
                                    <!-- End Row -->

                                    <!-- Location -->
                                    <div class="mb-3">
                                    <h3 class="text-capitalize">{{animal.animal_status}}</h3>
                                    </div>
                                    <!-- End Location -->

                                    <!-- Icon Blocks -->
                                    <ul class="list-inline list-separator font-size-1 mb-3">
                                    <li class="list-inline-item">
                                        <i class="fas fa-bed text-muted mr-1"></i>
                                        {{animal.breed}}
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fas fa-bath text-muted mr-1"></i>
                                        {{animal.gender}}
                                    </li>
                                    <li class="list-inline-item">
                                        <i class="fas fa-couch text-muted mr-1"></i>
                                        {{animal.age}}
                                    </li>
                                    </ul>
                                    <!-- End Icon Blocks -->
                                    <p class="font-size-1">{{animal.description}}</p>
                                    <!-- End Row -->
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <ul class="list-inline list-separator small text-body">
                                <li class="list-inline-item">Intake Date : {{animal.intake_date | formatDate}}</li>
                                <li class="list-inline-item">LOS : {{animal.los}} day<span v-if="animal.los > 1">s</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="card-footer pb-0 pt-3">
                    <pagination 
                        v-model="page" 
                        :records="total" 
                        theme="bootstrap4"
                        :edgeNavigation=true
                        :options="options"
                        @paginate="getAvailableAnimals"
                    />
                </div>
            </div>
                <div v-else> 
                    <h3> There are no animals currently available for foster. </h3>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Pagination from 'vue-pagination-2';

export default {
    components : {Pagination},
	data() {
		return {
			animals: {},
			isLoading :true,
            total : 0,
            page : 1,
            options : {
                texts : {
                    count:'Showing {from} to {to} of {count} animals|{count} animals|One animal',
                    first:'First',
                    last:'Last',
                    nextPage:'>',
                    nextChunk:'>>',
                    prevPage:'<',
                    prevChunk:'<<'
                }
            }
		}
	},

	mounted() {
		this.getAvailableAnimals(1)
	},
	computed: {
		...mapState(['user']),
	},
	methods: {
		getAvailableAnimals(page) {
            console.log('page', page)
			let data = {
				shelter_id: this.user.shelter_id,
                page : page
			}
			this.$store
				.dispatch('getAvailableAnimals', data)
				.then((response) => {
					this.animals = response.data.animals
                    this.total = response.data.total
                    this.isLoading = false
				})
				.catch((err) => console.log(err))
		},
	},
}
</script>

<style scoped></style>
