<template>
<div>
    <div class="container space-top-2">
        <div class="row align-items-center mb-5">
			<div class="col-sm mb-3 mb-sm-0">
				<h2 class="h2 mb-0">Photos</h2>
                
			</div>
        </div>
        <div class="row align-items-center mb-5">
			<div class="col-sm-auto">
                <div class="font-size-1">Drag and drop to order images</div>
                <Grid
                    :key="componentKey"
                    ref="grid"
                    :center="false"
                    :draggable="true"
                    :sortable="true"
                    :items="animal.photos || []"
                    :height="80"
                    :width="80"
                    :gridWith="100"
                    @change="change"
                    @remove="remove"
                    @click="click"
                    @sort="sort"
                    @dragend="onDragEnd"
                >
                    <template v-slot:cell="props">
                        <Photo 
                            :photo="props.item"
                            :index="props.index"
                            :with-button="showRemove"
                            @remove="props.remove()"
                        />
                    </template>
                </Grid>
            </div>
        </div>
        <div v-if="user.foster_edit_photos != false">
            <div class="row mb-1">
                <div class="col-md-12" >
                    <a class="font-size-1" @click="galleryIndex = 0" role="button">View Gallery</a> | 
                    <a class="font-size-1" @click="showRemove = !showRemove" role="button">Remove Photos</a>
                </div>
            </div>
            <vue-gallery-slideshow :images="galleryPhotos" :index="galleryIndex" @close="galleryIndex = null"></vue-gallery-slideshow>
            <hr>
            <div class="row mb-1">
                <div class="col-md-12">
                    Upload Photos
                </div>
                <div> 
                    <p :class="[uploadError ? 'text-danger' : 'text-info', 'mt-2']" v-if="uploadMessage">
                        {{uploadMessage}}
                    </p>
                </div>
                <div class="col-md-12">
                    <vue2Dropzone ref="myVueDropzone" 
                        id="dropzone" 
                        :options="dropzoneOptions"
                        @vdropzone-success="onUploadSuccess"
                        @vdropzone-error="onUploadError"
                        @vdropzone-queue-complete="onUploadComplete"
                        @vdropzone-processing="onUploadProcessing">
                    </vue2Dropzone>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import VueGallerySlideshow from 'vue-gallery-slideshow'

import Grid from './Grid.vue'
import { authComputed } from '../../../store/helpers.js'
import Photo from './Photo.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import config from '../../../store/config'

export default {
	name: 'AnimalProfilePhotos',
	components: { VueGallerySlideshow, Grid, Photo, vue2Dropzone },
	directives: {},
    props: {
		animal: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
            showRemove : false,
            componentKey : 1,
            galleryIndex: null,
            dropzoneOptions: {
                url: `${config.API_URL}/api/animal/${this.$route.params.animal_id}/photos`,
                useCustomDropzoneOptions : true,
                createImageThumbnails :false,
                autoProcessQueue: true,
                uploadMultiple: false,
                parallelUploads: 10,
                maxNumberOfFiles: 20,
                previewsContainer : false,
                acceptedFiles: '.jpg, .jpeg, .png, image/png, image/jpg, image/jpeg',
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                },
            },
            uploadMessage: '',
            uploadError: false,
        }
	},
	mounted() {},
    computed: {
        //...mapState(['user', 'animal']),
        ...authComputed,
        galleryPhotos() {
            if (this.animal) {
                return (this.animal.photos || []).map(p => `${this.animal.image_url}${p.filename}`)
            }

            return []
        },
		...mapState({
			user: 'user',
		}),
    },
	methods: {
        getWindowSize (){
            this.componentKey=+ 1
            this.$refs.grid.getWindowSize()
            setTimeout(() => {
                this.$refs.grid.getWindowSize()
            }, 300);
            
        },
        click ({ items, index }) {
            let value = items.find(v => v.index === index)
            this.selected = value.item
            console.log('clicked', this.selected)
        },
        change (event) {
            console.log('change', event)
        },
        remove (event) {
            console.log('remove', event)
            const fileToDelete = this.animal.photos[event.index].filename

            axios.delete(`${config.API_URL}/api/animal/${this.animal._id}/photos`, { data: { filename: fileToDelete } })
				.then((resp) => {
                    this.$store.commit('UPDATE_ANIMAL', resp.data)
                })
				.catch((err) => {
                    console.log('failed to remove file: ', err.response)
                })
        },
        sort (event) {
            console.log('sort', event)
        },
        onDragEnd(event) {
            console.log('dragend', event)
            const orderChanged = event.items.some((item, index) => item.index !== index)

            if (orderChanged) {
                axios.put(
                    `${config.API_URL}/api/animal/${this.animal._id}/photos`,
                    { photos: event.items.map(item => item.item) }
                ).then((resp) => {
                    this.$store.commit('UPDATE_ANIMAL', resp.data) // this is causing animation issue
                })
                .catch((err) => {
                    console.log('failed to update photos order: ', err.response)
                })
            }
        },
        onUploadProcessing(file){
            console.log('upload processing', file.name)
            this.uploadMessage = 'Photo(s) uploading...';
        },
        onUploadComplete(){
            console.log('upload complete')
            setTimeout(() => {
                this.uploadMessage = ''
                this.uploadError = false
            }, 7000)
        },
        onUploadError(file, message, xhr){
            console.log('upload error', file, message, xhr)
            this.uploadMessage = `There was an error while uploading the photo(s). - ${message}`;
            this.uploadError = true;
        },
        onUploadSuccess(uploaded) {
            console.log('upload success')
            this.uploadMessage = 'Photo successfully uploaded.'
			this.$store.commit('UPDATE_ANIMAL', JSON.parse(uploaded.xhr.response))
        }
    },
}
</script>

<style scoped>

    
</style>