<template>
<div>
	<div class="container space-top-2">
		<div class="row align-items-center mb-5">
			<div class="col-sm mb-3 mb-sm-0">
				<h2 class="h2 mb-0">Adoption Requests</h2>
			</div>
			<div class="col-sm-auto">
				<div class="btn-group">
					<button type="button" class="btn btn-outline-primary btn-xs dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<span class="text-capitalize">{{status}}</span>
					</button>
					<div class="dropdown-menu">
						<a class="dropdown-item" @click="getRequestsSpinner('all')">All</a>
						<a class="dropdown-item" @click="getRequestsSpinner('active')">Active</a>
						<a class="dropdown-item" @click="getRequestsSpinner('approved')">Approved</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" @click="getRequestsSpinner('declined')">Declined</a>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isLoading">
			<div class="d-flex justify-content-center">
				<div class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>
		<div v-else>
			<div v-if="animal && filteredRequests && filteredRequests.length > 0">
				<div v-for=" (request) in filteredRequests" :key="request._id">
					<AdoptRequest
						:request="request"
						:index="request._id"
					>
					</AdoptRequest>
				</div>
			</div>
			<div v-else>
				<h3><span v-if="animal.animal_name">{{animal.animal_name}}</span><span v-else>Unknown</span> does not have any <b v-if="status != 'all'" class="text-capitalize">{{status}}</b> adoption requests.</h3>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapState, mapGetters} from 'vuex'
import AdoptRequest from './AnimalProfileRequest.vue'
export default {
	name: 'AnimalProfileAbout',
	components: {AdoptRequest},
	directives: {},
	props: {
		animal: {
			type: Object,
			required: true,
		},
		requests:{
			type : Array,
		}
	},
	data() {
		return {
			status : 'active',
			isLoading :true,
			filteredRequests : {},
		}
	},
	mounted() {
		this.getRequests('active')
	},
	computed: {
		...mapState(['user']),
	},
	watch: { 
		requests: function(newVal, oldVal) { // watch it
			this.getRequests(this.status)
		}
	},
	methods: {
		getRequestsSpinner(status){
			this.isLoading = true
			setTimeout(() => {
				this.getRequests(status)
			}, 400)
		},
		getRequests(status='active') {
			if (this.requests){
				this.isLoading = false
				this.status = status
				
				switch(status){
					case 'declined' :
			 			this.filteredRequests = this.requests.filter(request => request.status == status);
						 break;
					case 'approved' :
						this.filteredRequests = this.requests.filter(request => request.status == status);
						break;
					case 'all' :
						this.filteredRequests = this.requests;
						break;
					case 'active': 
						this.filteredRequests = this.requests.filter(request => request.status == 'submitted' || request.status == 'pending' || request.status == 'approved' || request.status == 'paid' || request.status == 'adopted'   );
				}
			}else{
				return {}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
</style>