<template>
    <div>
        <h5>{{field.label}}</h5>
        <p
            class="editable-content"
            data-toggle="modal"
            :data-target="'#microchip_edit_modal_' + animal_id"
            @click="toggleEdit()"
            v-if="editable"
        >
            <span v-if="microchip">
                {{microchip}}<span v-if="microchipIssuer"> / {{microchipIssuer}}</span>
            </span>
            <span v-else>
                <i>Enter Microchip</i>
            </span>
        </p>
        <p v-else>
            {{microchip}}<span v-if="microchipIssuer"> / {{microchipIssuer}}</span>
        </p>

        <EditMicrochipModal
			v-if="editable"
			:key="modalKey"
			:animal_id="animal_id"
			:microchip="this.microchip"
            :microchipIssuer="this.microchipIssuer"
		>
		</EditMicrochipModal>
    </div>
</template>

<script>
export default {
    name: 'BaseMicrochipField',
    components: {  },
    directives: {  },
    props : {
        animal_id: {
            type : String,
        },
        field: {
            type : Object,
            required :true
        },
        microchip: {
            type : String,
        },
        microchipIssuer: {
            type : String,
        },
        editable : {
            type : Boolean,
        },
    },
    data() {
        return {
            componentKey: 0,
        };
    },
    mounted() {
        
    },
    computed :{
        modalKey() { return `${this.animal_id}_${this.componentKey}` }
    },
    methods: {
        toggleEdit() {
            this.componentKey += 1
        }
    },
};
</script>

<style lang="scss" scoped>
.editable-content {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
}
</style>
