<template>
<div>
	<div class="card card-bordered card-hover-shadow mb-5">
		<div class="card-body">
			<h4 class="text-capitalize">{{exam.exam_date |formatDate}} - {{exam.form.form_title}}</h4>
			<p>
				<a class="text-primary" 
					data-toggle="collapse" 
					:data-target="'#collaspe_'+exam._id" 
					role="button" aria-expanded="false" aria-controls="index"
					@click="showDetail =! showDetail"
					>
					<span v-if="showDetail">View Detail</span>
					<span v-else>Hide Detail</span>
				</a>
			</p>
			<Exam
				:id="'collaspe_'+exam._id"
				:exam="exam"
			>
			</Exam>
		</div>
	</div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import Exam from './Exam'

export default {
	name: 'exams',
	components: {Exam},
	directives: {},
	props: {
		exam: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showDetail : true
		}
	},
	mounted() {},
	computed: {
		...mapState(['user']),
	},
	methods: {
		
	},
}
</script>

<style lang="scss" scoped>
</style>