<template>
<div>
    <div class="container">
      <!-- Nav Scroller -->
      	<div id="pageHeaderTabParent">
			<div class="js-nav-scroller hs-nav-scroller-horizontal bg-white">
				<span class="hs-nav-scroller-arrow-prev" style="display: none;">
					<a class="hs-nav-scroller-arrow-link" href="javascript:;">
						<i class="fas fa-angle-left"></i>
					</a>
				</span>

			<span class="hs-nav-scroller-arrow-next" style="display: none;">
				<a class="hs-nav-scroller-arrow-link" href="javascript:;">
					<i class="fas fa-angle-right"></i>
				</a>
			</span>
	
			<!-- Nav -->
			<ul class="js-scroll-nav nav nav-tabs page-header-tabs bg-white" id="pageHeaderTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" data-toggle="tab" href="#about-section" role="tab" aria-controls="about" aria-selected="true">About</a>
				</li>
				<li class="nav-item" v-if="animal && animal.location_status == 'Foster' && user.foster_adopt_requests != false">
					<a class="nav-link" data-toggle="tab" href="#adoptions-section" role="tab" aria-controls="adoptions" aria-selected="true">
						Requests
						<span v-if="animal && activeRequests.length > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{activeRequests.length}}</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#notes-section" role="tab" aria-controls="notes" aria-selected="true">Notes</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#medical-section" role="tab" aria-controls="medical" aria-selected="true">Medical
						<span v-if="animal && user.foster_view_medical != false && medicalDetails > 0" class="badge badge-info badge-pill ml-1" role="tab" aria-controls="medial" aria-selected="true">+{{medicalDetails}}</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#files-section" role="tab" aria-controls="files" aria-selected="true">Files</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#photos-section" role="tab" aria-controls="photos" aria-selected="true" 
						@click="getWindowSizeForPhotos" >
						Photos
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#videos-section" role="tab" aria-controls="videos" aria-selected="true">
						Videos
					</a>
				</li>
			</ul>
			<!-- End Nav -->
			</div>
      	</div>
      <!-- End Nav Scroller -->
    </div>
</div>
</template>

<script>
import HsNavScroller from '../../assets/vendor/hs-nav-scroller/dist/hs-nav-scroller.min.js'
import HSScrollNav from '../../assets/vendor/hs-scroll-nav/dist/hs-scroll-nav.min.js'
import { mapState } from 'vuex'

export default {
    name: 'AnimalProfileNavbar',
    components: {},
    directives: {},
    data() {
        return {}
    },
    mounted() {
        this.navScroller()
    },
	computed: {
		medicalDetails(){
			return this.animal.medicalExams.length+this.animal.medicalMedications.length+this.animal.medicalVaccinations.length+this.animal.medicalDiagnostics.length+this.animal.medicalSurgeries.length;
		},
		...mapState(['user', 'animal']),
		activeRequests(){
			return this.animal.requests.filter(request => request.status == 'submitted' || request.status == 'pending' || request.status == 'approved' || request.status == 'paid' || request.status == 'adopted'   );
		}
	},
    methods: {
		getWindowSizeForPhotos(){
			console.log('Set window size flag')
			this.$store.commit('SET_WINDOW_SIZE_FLAG', true)
			this.$emit("getWindowSize")
		},
        navScroller() {
            $(document).on('ready', function () {
                // INITIALIZATION OF NAV SCROLLER
                // =======================================================
                $('.js-nav-scroller').each(function () {
                    new HsNavScroller($(this)).init()
                })

                // INITIALIZATION OF SCROLL NAV
                // =======================================================
                $('.js-scroll-nav').each(function () {
                    var scrollNav = new HSScrollNav($(this)).init()
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>