<template>
<div>
	<div class="card card-bordered card-hover-shadow mb-5">
		<div class="card-body">
			<div class="d-sm-flex">
				<div class="media-body">
					<div class="row">
						<div class="col col-md-9">
							<h3>{{request.adopter.first_name}} {{request.adopter.last_name}}</h3>
							<h5>
								<span class=" text-capitalize"
								:class="{
									'badge badge-warning': request.people.adopter_status === 'submitted',
									'badge badge-danger': request.people.adopter_status === 'pending',
									'badge badge-success': request.people.adopter_status === 'approved', 
									'badge badge-white': request.people.adopter_status === 'canceled', 
								}"
								>Application {{request.people.adopter_status}}</span>
							</h5>

							<h4 v-if="request.status == 'paid' || request.status == 'adopted'" class="text-capitalize text-danger"><i class="fa fa-check"></i> Adoption Fee {{request.status}} : {{paidDate | formatDateTime}}</h4>

							<p class="font-size-1 text-body">
								{{request.adopter.address_1}}<br>
								<span v-if="request.adopter.address_2">{{request.adopter.address_2}}<br></span>
								{{request.adopter.city}}, {{request.adopter.city}} {{request.adopter.zip}}<br>
								<a href="mialto:request.adopter.email">{{request.adopter.email}}</a><br>
								{{request.adopter.phone | formatPhoneNumber}}<br>
								<a v-if="request.people.facebook_url" :href="request.people.facebook_url" target= "_blank">Facebook Link </a><br>
							</p> 
							<p>
								<a class="link-collapse-defaul" data-toggle="collapse" :data-target="'#collapse'+index" role="button" aria-expanded="false" aria-controls="index"
									@click="showApplication =! showApplication">
									<span v-if="showApplication">View Application</span>
									<span v-else>Hide Application</span>
								</a>
							</p>
							<AdopterApplication
								:id="'collapse'+index"
								:application="request.application"
							>
							</AdopterApplication>
							
						</div>
						<!-- Desktop -->
						<div class="col-12 col-md-3 mt-3 mt-md-0 d-none d-md-block">
							<div v-if="request.status == 'unavailable'">
								<div class="d-flex justify-content-end">
									<span class="badge badge-light">Not Selected</span>
								</div>
							</div>
							<div v-if="request.status == 'adopted'">
								<div class="d-flex justify-content-end">
									<span class="badge badge-success">Adopted</span>
								</div>
							</div>
							<div v-if="request.status == 'reversed'">
								<div class="d-flex justify-content-end">
									<span class="badge badge-danger">Reversed</span>
								</div>
							</div>
							<div v-if="request.status == 'returned'">
								<div class="d-flex justify-content-end">
									<span class="badge badge-danger">Returned Adoption</span>
								</div>
							</div>
							<div v-if="request.status == 'paid'">
								<div class="d-flex justify-content-end">
									<button class="btn btn-xs btn-success" data-toggle="modal" :data-target="'#completeAdoption'+index" @click="completeAdoption(request.adopter)">Complete Adoption</button>
								</div>
							</div>
							<div v-if="request.status == 'approved'">
								<div class="d-flex justify-content-end">
									<button class="btn btn-xs btn-outline-secondary" data-toggle="modal" :data-target="'#unApproveModal'+index" @click="unApproveModal(request.adopter)">Unapprove</button>
								</div>
							</div>
							<div v-if="request.status == 'declined'">
								<div class="d-flex justify-content-end">
									<span v-if="animal.animal_status == 'adopted'" class="badge badge-warning">Declined</span>
									<button v-else class="btn btn-xs btn-outline-secondary" data-toggle="modal" :data-target="'#unDeclineModal'+index" @click="unDeclineModal(request.adopter)">Undecline</button>
								</div>
							</div>
							<div v-if="request.status == 'submitted' || request.status == 'pending'">
								<p class="text-right">Approve adoption request?</p>
								<div class="d-flex justify-content-end">
									<button class="btn btn-xs btn-primary mr-3" :disabled="adopterStatus" data-toggle="modal" :data-target="'#approveModal'+index" @click="approveModal(request.adopter)">Yes</button>
									<button class="btn btn-xs btn-danger" data-toggle="modal" :data-target="'#declineModal'+index" @click="declineModal(request.adopter)">No</button>
								</div>
							</div>
						</div>
						<!-- Mobile View -->
						<div class="col-12 col-md-3 mt-3 mt-md-0 d-sm-block d-md-none">
							<hr>
							<div v-if="request.status == 'unavailable'">
								<div class="d-flex justify-content-between">
									<span class="badge badge-light">Not Selected</span>
								</div>
							</div>
							<div v-if="request.status == 'adopted'">
								<div class="d-flex justify-content-between">
									<span class="badge badge-success">Adopted</span>
								</div>
							</div>
							<div v-if="request.status == 'reversed'">
								<div class="d-flex justify-content-between">
									<span class="badge badge-danger">Reversed</span>
								</div>
							</div>
							<div v-if="request.status == 'returned'">
								<div class="d-flex justify-content-between">
									<span class="badge badge-danger">Returned Adoption</span>
								</div>
							</div>
							<div v-if="request.status == 'paid'">
								<div class="d-flex justify-content-between">
									<button class="btn btn-xs btn-success" data-toggle="modal" :data-target="'#completeAdoption'+index" @click="completeAdoption(request.adopter)">Complete Adoption</button>
								</div>
							</div>
							<div v-if="request.status == 'approved'">
								<div class="d-flex justify-content-between">
									<button class="btn btn-xs btn-outline-secondary" data-toggle="modal" :data-target="'#unApproveModal'+index" @click="unApproveModal(request.adopter)">Unapprove</button>
								</div>
							</div>
							<div v-if="request.status == 'declined'">
								<div class="d-flex justify-content-between">
									<span v-if="animal.animal_status == 'adopted'" class="badge badge-warning">Declined</span>
									<button v-else class="btn btn-xs btn-outline-secondary" data-toggle="modal" :data-target="'#unDeclineModal'+index" @click="unDeclineModal(request.adopter)">Undecline</button>
								</div>
							</div>
							<div v-if="request.status == 'submitted' || request.status == 'pending'">
								<p class="">Approve adoption request?</p>
								<div class="d-flex justify-content-between">
									<button class="btn btn-xs btn-primary mr-3" :disabled="adopterStatus" data-toggle="modal" :data-target="'#approveModal'+index" @click="approveModal(request.adopter)">Yes</button>
									<button class="btn btn-xs btn-danger" :disabled="adopterStatus" data-toggle="modal" :data-target="'#declineModal'+index" @click="declineModal(request.adopter)">No</button>
								</div>
							</div>
						</div>
					</div>
					<!-- End Row -->
				</div>
			</div>
		</div>
		<div class="card-footer">
			<ul class="list-inline list-separator small text-body">
				<li v-if="request.status === 'adopted'" class="list-inline-item text-capitalize">Adopted {{requestAge}}</li>
				<li v-else class="list-inline-item text-capitalize">Submitted {{requestAge}}</li>
			</ul>
		</div>
	</div>
	<ApproveDenyModal
			v-if="showModal"
			:key="'modalId'+componentKey+index"
			:id="modalId"
			:title="title"
			:action="action"
			:color="color"
			:adopter="adopter"
			:request="request"
			:animal="animal"
			@action="action"
			:index="'action_'+componentKey+index"
		>
	</ApproveDenyModal>

	<CompleteAdoptionModal
			v-if="showCompleteModal"
			:key="'completeModalId'+componentKey+index"
			:id="completeModalId"
			:action="action"
			:title="title"
			:color="color"
			:adopter="adopter"
			:request="request"
			:animal="animal"
			@action="action"
			:index="'complete_'+componentKey+index"
			@showToaster="showToaster"
		>
		</CompleteAdoptionModal>

</div>
</template>

<script>
import { mapState } from 'vuex'
import AdopterApplication from './AdopterApplication.vue'
import ApproveDenyModal from './ApproveDenyModal.vue'
import CompleteAdoptionModal from './CompleteAdoptionModal.vue'
import moment from 'moment-timezone'


export default {
	name: 'AnimalProfileAbout',
	components: {
		AdopterApplication, 
		ApproveDenyModal, 
		CompleteAdoptionModal,
	},
	directives: {},
	props: {
		request: {
			type: Object,
			required: true,
		},
		index: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			componentKey : 1,
			showApplication: true,
			showModal : false,
			modalId : null,
			showCompleteModal : false,
			completeModalId : null,
			color : null,
			title : null,
			action : null,
		}
	},
	mounted() {},
	computed: {
		...mapState(['user', 'animal']),
		requestAge(){
			return moment(this.request.created_at).fromNow();
		},
		adoptedAge(){
			return moment(this.request.paid_date).fromNow();
		},
		adopterStatus(){
			return this.request.people.adopter_status != 'approved' ? 'disabled' : null
		},
		paidDate (){
			return moment(this.request.paid_date);
		}
	},
	methods: {
		approveModal(adopter){
			this.showModal = true;
			this.componentKey+= 1
			this.modalId = 'approveModal'+this.index
			this.adopter = adopter
			this.title = "Approve"
			this.action = "approve"
			this.color = "btn-primary"
		},
		declineModal(adopter){
			this.showModal = true;
			this.componentKey+= 1
			this.modalId = 'declineModal'+this.index
			this.adopter = adopter
			this.title = "Decline"
			this.action = "decline"
			this.color = "btn-danger"
		},
		unDeclineModal(adopter){
			this.showModal = true;
			this.componentKey+= 1
			this.modalId = 'unDeclineModal'+this.index
			this.adopter = adopter
			this.title = "Undecline"
			this.action = "undecline"
			this.color = "btn-danger"
		},
		unApproveModal(adopter){
			this.showModal = true;
			this.componentKey+= 1
			this.modalId = 'unApproveModal'+this.index
			this.adopter = adopter
			this.title = "UnApprove"
			this.action = "unapprove"
			this.color = "btn-danger"
		},
		completeAdoption(adopter){
			this.showCompleteModal = true;
			this.componentKey+= 1
			this.completeModalId = 'completeAdoption'+this.index
			this.adopter = adopter
			this.action = "complete"
			this.title = "Complete Adoption"
			this.color = "btn-success"
		},
		showToaster(messages){
			if(messages){
				Object.keys(messages).forEach(key => {
					if (key == "outcome"){
						this.$toasted.success(messages[key], { 
							theme: "bubble", 
							position: "top-center", 
							duration : 3000,
							className : 'center',
							containerClass : 'center'
						});
					}

					if (key == "microchip"){
						this.$toasted.error(messages[key], { 
							theme: "bubble", 
							position: "top-center", 
							duration : 6000,
							action : {
								text : 'Close',
								onClick : (e, toastObject) => {
									toastObject.goAway(0);
								}
							},
						});
					}
				});	
			}		
		}
	},
}
</script>

<style lang="scss" scoped>
</style>