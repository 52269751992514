<template>
  <div class="register">
    <div class="container space-2 space-lg-3">
      <div class="row no-gutters">
        <div
          class="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0"
        >
          <!-- Form -->

          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(register)">
              <!-- Title -->
              <div class="mb-5 mb-md-7">
                <h1 class="h2">Welcome to Animals First Foster App</h1>
                <p>Please Register to get started.</p>
              </div>
              <!-- End Title -->

              <!-- Form Group -->
              <ValidationProvider rules="email|required|min:6" v-slot="v">
                <div class="js-form-message form-group">
                  <label class="input-label" for="signinSrEmail">Email address</label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="signinSrEmail"
                    placeholder="Email address"
                    aria-label="Email address"
                    v-model="email"
                  />
                  <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                </div>
              </ValidationProvider>
              <!-- End Form Group -->

              <!-- Form Group -->
              <ValidationProvider rules="required|min:6" v-slot="v" vid="password">
                <div class="js-form-message form-group">
                  <label class="input-label" for="signinSrPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    id="signinSrPassword"
                    placeholder="********"
                    aria-label="********"
                    v-model="password"
                  />
                  <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                </div>
              </ValidationProvider>
              <!-- End Form Group -->

              <!-- Form Group -->
              <ValidationProvider rules="required|confirmed:password" v-slot="v">
                <div class="js-form-message form-group">
                  <label class="input-label" for="signinSrConfirmPassword"
                    >Confirm password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    name="confirmPassword"
                    id="signinSrConfirmPassword"
                    placeholder="********"
                    aria-label="********"
                    v-model="password_confirmation"
                  />
                  <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                </div>
              </ValidationProvider>
              <!-- End Form Group -->

              <!-- Checkbox -->
              <!--<div class="js-form-message mb-5">
                <div
                  class="custom-control custom-checkbox d-flex align-items-center text-muted"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="termsCheckbox"
                    name="termsCheckbox"
                    required
                    data-msg="Please accept our Terms and Conditions."
                  />
                  <label class="custom-control-label" for="termsCheckbox">
                    <small>
                      I agree to the
                      <a class="link-underline" href="@@autopath/page-terms.html"
                        >Terms and Conditions</a
                      >
                    </small>
                  </label>
                </div>
              </div>-->
              <!-- End Checkbox -->

              <p v-if="error" class="text-danger">{{ error }}</p>

              <!-- Button -->
              <div class="row align-items-center mb-5">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <span class="font-size-1 text-muted"
                    >Already have an account?
                  </span>
                  <router-link to="/" name="login">
                    <a class="font-size-1 font-weight-bold" href="">Login</a>
                  </router-link>
                </div>

                <div class="col-sm-6 text-sm-right">
                  <button
                    type="submit"
                    class="btn btn-primary transition-3d-hover"
                  >
                    Get Started
                  </button>
                </div>
              </div>
              <!-- End Button -->
            </form>
          </ValidationObserver>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      is_admin: null,
			error: null,
      invite_code: this.$route.query.invite_code || '',
    }
  },

  methods: {
    register() {
      this.error = null
      let data = {
        email: this.email,
        password: this.password,
        invite_code: this.invite_code,
      }
      this.$store
        .dispatch('register', data)
        .then((resp) => {
          console.log('reesolved: ', resp)
          if (this.invite_code) {
						this.$router.push({ name : 'invites', query: { invite_code: this.invite_code } })
          } else {
            this.$router.push('/animals')
          }
        })
        .catch((err) => {
          console.log('register error', err)
					this.error = err.response.data
        })
    },
  },
}
</script>
