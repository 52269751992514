<template>
    <ValidationProvider :vid="item.name" :rules="isRequired" v-slot="v">
        <div class="row form-group">
            <label 
                class="col-form-label input-label"
                :class="[labelSize, {'text-danger' : v.errors[0]}]"
                >{{label}}
                <span v-if="!item.required" class="input-label-secondary">(Optional)</span>
            </label>
            <div :class="inputSize">
                <textarea 
                    class="form-control" 
                    :class="{'is-invalid' : v.errors[0]}"
                    rows="3" 
                    :name="item.name" 
                    v-model="selectedValue" 
                    @change="updateInput"
                    :disabled="disabled">
                </textarea>
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>

    import { ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        components : {
            ValidationProvider
        },
        props : {
            id : {
                type : String,
                default : "textarea_"
            },
            item :{
                type : Object,
                required :true,
            },
            value : {
                type : String,
                required :false,
            },
            labelSize: {
                type : String,
                default : 'col-sm-3'
            },
            inputSize: {
                type : String,
                default : 'col-sm-9'
            },
            disabled : {
                type : String,
                default : null
            }
        },
        data () {
            return {
                selectedValue : this.value,
                label : this.item.label
            }
        },
        watch: { 
            value: function(newVal, oldVal) { // watch it
                this.selectedValue = newVal;
            }
        },
        computed :{
           isRequired(){
                return this.item.required == true ? 'required' : ''
            },
        },
        methods: {
            updateInput () {
                this.$emit('input', event.target.value)
            }
        }
    }
</script>

<style scoped>
    .form-control.textarea{
        height: auto;
   }
</style>