<template>
	<div>
		<div id="about-section" class="container space-top-2">
			<h3>Medical Summary </h3>
			<hr>
			<div class="container">
                <div class="row">
                    <div class="col-md-6" v-if="animal.medicalMedications && animal.medicalMedications.length > 0">
                    <h5 class="m-1">Medications</h5>
                    <div class="mb-3" v-for="medication, index in animal.medicalMedications" :key="medication.id">
                        <div class="row">
                            <div class="col-md-6">{{ medication.template_name }}</div>
                        </div>
                            <div class="row" v-if="medication.reason">
                            <div class="col-md-12"><i>{{ medication.reason }}</i></div>
                        </div>
                    </div>
                    <hr>
                    </div>
                    <div class="col-md-6" v-if="animal.medicalVaccinations && animal.medicalVaccinations.length > 0">
                    <h5 class="m-1">Vaccinations</h5>
                    <div  class="mb-3" v-for="vaccination, index in animal.medicalVaccinations" :key="vaccination.id">
                        <div class="row">
                        <div class="col-md-6">{{ vaccination.template_name }}</div>
                        </div>
                    </div>
                    <hr>
                    </div>
                    <div class="col-md-6" v-if="animal.medicalDiagnostics && animal.medicalDiagnostics.length > 0">
                    <h5 class="m-1">Diagnostics</h5>
                    <div class="mb-3" v-for="diagnostic, index in animal.medicalDiagnostics" :key="diagnostic.id">
                        <div class="row">
                        <div class="col-md-6">{{ diagnostic.template_name }}</div>
                        </div>
                    </div>
                    <hr>
                    </div>
                    <div class="col-md-6" v-if="animal.medicalExams && animal.medicalExams.length > 0">
                    <h5 class="m-1">Exams</h5>
                    <div class="mb-3" v-for="exam, index in animal.medicalExams" :key="exam.id">
                        <div class="row">
                        <div class="col-md-6">{{ exam.form.form_title }}</div>
                        </div>
                    </div>
                    <hr>
                    </div>
                    <div class="col-md-6" v-if="animal.medicalSurgeries && animal.medicalSurgeries.length > 0">
                    <h5 class="m-1">Surgeries</h5>
                    <div class="mb-3" v-for="surgery, index in animal.medicalSurgeries" :key="surgery.id">
                        <div class="row">
                        <div class="col-md-6">{{ surgery.template_name }}</div>
                        </div>
                    </div>
                    <hr>
                    </div>
                </div>
            </div>
			<!-- End Divider -->
		</div>
	</div>
</template>

<script>
import { authComputed } from '../../../../store/helpers.js'
import { mapState } from 'vuex'
export default {
	name: 'MedicalSummary',
	data() {
		return {
		}
	},
	computed: {
        ...mapState({
			user: 'user',
			animal: state => state.animal || {},
		}),
		...authComputed,
		
	},
}
</script>


<stylescoped>
.col-lg-6{
    padding:10px;
}
.col-lg-6 hr{
        margin: 5px;
}
.row{
    font:menu;
}
</style>