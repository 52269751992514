<template>
	<div>
		<div id="about-section" class="container space-top-2">
			<h2>About {{animal.animal_name}}</h2>
			<BaseDescriptionField 
				v-if="!editable"
				:description="animal.description" 
			>
			</BaseDescriptionField>
			<EditableValue
				v-else
				:value="animal.description"
				:multiline="true"
				:inputProps="{ rows: 3, required: true }"
				@change="onFieldChange('description', $event)"
				#default="{ value, click }"
			>
				<BaseDescriptionField 
					:description="value" 
					@click="click"
					>
				</BaseDescriptionField>
			</EditableValue>
			<hr>
			<div class="row" v-if="animal && animal.template" >
				<AnimalFields v-for="field, index in animal.template.fields" :key="index"
					:field="field"
					:animal="animal"
				>
				</AnimalFields>
			</div>

			<!-- Divider -->
			<div class="container">
				<hr class="my-10" />
			</div>
			<!-- End Divider -->
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '../../../store/helpers.js'
import AnimalFields from './AnimalFields'
export default {
	name: 'AnimalProfileAbout',
	components: {AnimalFields},
	directives: {},
	props: {
		animal: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			
		}
	},
	created() {},
	mounted() {},
	computed: {
		...authComputed,
		...mapState(['user']),

		photo_url() {
			if (this.animal) {
				return (
					this.photoUrl +
					'/' +
					this.animal.shelter_id +
					'/' +
					this.animal._id +
					'/images'
				)
			}
			return ''
		},
		images() {
			let vm = this
			let images = []
			console.log('this.animal', this.animal.photos)
			if (this.animal && this.animal.photos) {
				this.animal.photos.forEach(async function (photo) {
					let photo_url =
						vm.photoUrl +
						'/' +
						vm.animal.shelter_id +
						'/' +
						vm.animal._id +
						'/images/' +
						photo.filename
					await images.push(photo_url)
				})
				return images
			}
			return []
		},
		editable(){
            return this.animal && this.animal.location_status && this.animal.location_status.toLowerCase() == 'foster' && this.user.foster_edit_bio != false ? true : false
        }
	},
	methods: {
		onFieldChange(type, value) {
			console.log('field chagne: ', type, value);
			// this.animal.description = value;
			this.$store.dispatch('updateAnimal', { animal_id: this.animal._id, data: { [type]: value, old_data:this.animal[type]}})
				.then((response) => {
					console.log('updated aniaml', response.data)
				})
				.catch((err) => console.log(err))
		}
	},
}
</script>


<style lang="scss" scoped>

</style>