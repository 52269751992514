<template>
    <div class="container p-0 space-top-2">
        <div class="row p-0 col-12 mb-3 m-0">
            <div v-if="animal.medicalVaccinations.length == 0"> 
                    <div class="col-lg-12 text-center m-b-md"> You do not have any Vaccinations </div>
            </div>       
            <div v-else class="card card-bordered card-hover-shadow mb-5 pt-3 pl-3 float-e-margins p-0 col-12" v-for="(vaccination, index) in animal.medicalVaccinations" :key="vaccination.id">
                <Vaccination 
                    :vaccination="vaccination" 
                    :index="index"
                    > 
                </Vaccination>
            </div>
        </div>
    </div>
</template>

<script>
import { authComputed } from '../../../../store/helpers.js'
import Vaccination from './Vaccination'
import { mapState } from 'vuex'
export default {
	name: 'Vaccinations',
	components: {Vaccination},
	directives: {},
	data() {
		return {
			
		}
	},
	computed: {
        ...mapState({
			user: 'user',
			animal: state => state.animal || {},
		}),
		...authComputed,
		
	},
	methods: {
		
	},
}
</script>


<style lang="scss" scoped>
.col-lg-6{
        padding:10px;

        hr{
            margin: 5px;
        }
    }
.row{
    font:menu;
}
.card{
    min-height: 131px;
}
</style>