import { render, staticRenderFns } from "./BaseBirthdayField.vue?vue&type=template&id=2985e4ec&scoped=true"
import script from "./BaseBirthdayField.vue?vue&type=script&lang=js"
export * from "./BaseBirthdayField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2985e4ec",
  null
  
)

export default component.exports