export default {
    methods : {
        showToaster(type, message){
            if(type && message){
                if (type == "success"){
                    this.$toasted.success(message, { 
                        theme: "bubble", 
                        position: "top-center", 
                        duration : 3000,
                        className : 'center',
                        containerClass : 'center'
                    });
                }
                if (type == "error"){
                    this.$toasted.error(message, { 
                        theme: "bubble", 
                        position: "top-center", 
                        duration : 3000,
                        className : 'center',
                        containerClass : 'center'
                    });
                }
            }		
        }
    }
}