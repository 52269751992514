<template>
    <div class="containerx space-top-2">
        <div class="row align-items-center mb-5">
            <div class="col-sm mb-3 mb-sm-0">
                <h3 class="text-capitalize">Weight History</h3>
            </div>
            <div class="col-sm-auto">
                <button class="btn btn-xs btn-primary pull-right"
                    data-toggle="modal"
                    :data-target="'#weight_add_modal_' + animal._id"
                    @click="addWeight()"
                >
                    + Weight
                </button>
            </div>
        </div>
        <div class="row align-items-center mb-5">
            <div class="col-sm mb-3 mb-sm-0">
                <div v-if="!animal.weights || animal.weights.length === 0" class="text-center">
                    <div class="mb-3">You do not have any Medical Weights</div>
                    <button class="btn btn-sm btn-primary"
                        data-toggle="modal"
                        :data-target="'#weight_add_modal_' + animal._id"
                        @click="addWeight()"
                    >
                        Add Weight
                    </button>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-sm-3 mb-3">
                            <h5 class="mb-2=3">Weight List</h5>
                            <div>
                                <div v-for="weight, index in sortDates" :key="index" class="p-0">
                                <Weight
                                    :animal_id="animal._id"
                                    :weight="weight"
                                    :index="index"
                                    :unit="unit"
                                    @editWeight="editWeight"
                                >
                                </Weight>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-9" v-if="animal.weights && animal.weights.length > 0">
                            <div class="btn-group pull-right">
                                <button type="button" class="btn btn-sm btn-white" :class="{'active' : showLbs}" @click="showData('lbs')">lbs</button>
                                <button type="button" class="btn btn-sm btn-white" :class="{'active' : showKg}" @click="showData('kg')">kg</button>
                            </div>
                            <div class="small">
                                <WeightChart
                                :chartData="chartData"
                                :options="options"
                                :styles="{height : '200px'}"
                                >
                                </WeightChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
            <EditWeightModal
                v-if="showEditWeight"
                :key="modalEditKey"
                :animal_id="animal._id"
                :weight="weight"
                @updateChart="mapData"
            >
            </EditWeightModal>
            <AddWeightModal
                v-if="showAddWeight"
                :key="modalAddKey"
                :animal_id="animal._id"
                @updateChart="mapData"
            >
            </AddWeightModal>
        </div>
    </div>
</template>

<script>

import WeightChart from './weight-chart.vue'
import EditWeightModal from '../../../base-components/EditWeightModal.vue'
import Weight from './weight.vue'
import AddWeightModal from '../../../base-components/AddWeightModal.vue'

export default {
    props: ['animal', 'medicalData', 'isLoading'],
    components: {
        WeightChart,
        Weight,
        AddWeightModal,
        EditWeightModal
    },
    data() {
        return {
            componentDataLoading : false,
            chartData : null,
            weights : null,
            dates : null,
            unit : 'lbs',
            weightEditKey : 1,
            weightAddKey : 1,
            showAddWeight : false,
            showEditWeight : false,
            weight : null,
            showLbs : true,
            showKg : false,
            options : {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    xAxes: [ {
                        gridLines: {
                            display: true
                        }
                    }]
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';

                            if (label) {
                                label += ': ';
                            }

                            label += tooltipItem.yLabel;
                            return label;
                        }
                    }
                }
            },
        };
    },
    mounted(){
        this.mapData();
    },
    computed : {
        sortDates(){
            return _.orderBy(this.animal.weights, 'date', 'desc')
        },
        modalAddKey() { return `${this.animal._id}_${this.weightAddKey}` },
        modalEditKey() { return `${this.animal._id}_${this.weightEditKey}` },
    },
    methods: {
        mapData(){
            var vm = this
            var sortedWeights = _.orderBy(this.animal.weights, 'date', 'asc')

            this.weights = sortedWeights.map(function(weight){
                var convertedWeights = [];
                if(vm.unit == 'lbs'){
                    if( weight.unit == 'kg')
                    {
                        return (weight.weight / 0.453592).toFixed(2)
                    }
                    else if(  weight.unit == 'g') //grams to lbs
                    {
                        return (weight.weight * 0.0022046).toFixed(2)
                    }
                    else if( weight.unit == 'oz')
                    {
                        return (weight.weight * 0.0625).toFixed(2)
                    }
                    else
                    {
                        return (weight.weight * 1).toFixed(2)
                    }
                }

                if(vm.unit == 'kg'){
                    if( weight.unit == 'lbs')
                    {
                        return (weight.weight * 0.453592).toFixed(2)
                    }
                    else if(weight.unit == 'oz')
                    {
                        return (weight.weight * 0.0283495).toFixed(2)
                    }
                    else if(weight.unit == 'g')
                    {
                        return (weight.weight / 1000).toFixed(2)
                    }
                    else
                    {
                        return (weight.weight *1).toFixed(2)
                    }
                }
                return convertedWeights;
            })
            this.dates = sortedWeights.map(weight => {
            const date = new Date(weight.date);
            const formattedDate = `${date.getMonth() + 1}-${date.getDate()}`;
            return formattedDate;
            });            
            this.chartData = {
                labels: this.dates,
                datasets: 
                [
                    {   
                        label : 'Weight',
                        borderColor: '#249EBF',
                        data: this.weights,
                    }
                ]
            }
            this.componentDataLoading = false;
        },
        editWeight(weight){
            this.weightEditKey += 1;
            this.showEditWeight = true;
            this.weight = weight
        },
        addWeight(){
            this.showAddWeight = true
            this.weightAddKey += 1
        },
        showData(type){
            this.unit = type;
            switch (type) {
            case 'lbs' :
                this.showLbs = true;
                this.showKg = false;
                this.mapData()
                break;
            case 'kg' :
                this.showLbs = false;
                this.showKg = true;
                this.mapData()
                break;
           }
        },
    }
}
</script>
<style scoped>
    .small {
        max-width: 100%;
        margin: 25px auto;
    }
    .ps {
      height: 200px;
    }
   .row{
    font:menu;
}
</style>