<template>
    <ValidationProvider :vid="item.name" :rules="isRequired" v-slot="v">
        <div class="row form-group">
            <label class="col-form-label input-label" :class="[labelSize, {'text-danger' : v.errors[0]}]">{{label}}
                <span v-if="!item.required" class="input-label-secondary">(Optional)</span>
            </label>
            <div :class="inputSize">
                <div class="input-group radio input-group-down-break">
                    <div class="form-control" v-for="(value, index) in values" :key="index">
                        <div class="custom-control custom-radio">
                            <input type="radio" 
                                class="custom-control-input" 
                                :class="{'is-invalid' : v.errors[0]}"
                                :name="item.name" 
                                :value="getValue(value,index)"
                                v-model="selectedValue" 
                                :id="id+'_'+item.name+'_'+index"
                                @change="updateInput"
                                :disabled="disabled"
                                
                            >                      
                            <label class="custom-control-label" :for="id+'_'+item.name+'_'+index">
                                {{value}}
                            </label>
                        </div>
                    </div>
                </div>
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        components : {
            ValidationProvider
        },
        props : {
            id : {
                type : String,
                default : "radio_"
            },
            item :{
                type : Object,
                required : true,
            },
            value : {
                type : String,
                required :false,
            },
            error : {
                type : String,
                required : false,
                default : null
            },
            labelSize: {
                type : String,
                default : 'col-sm-3'
            },
            inputSize: {
                type : String,
                default : 'col-sm-9'
            },
            useIndex: {
                type : Boolean,
                default: false
            },
            disabled : {
                type : String,
                default : null
            }
        },

        data () {
            return {
                selectedValue : this.value,
                label : this.item.label
            }
        },
        watch: { 
            value: function(newVal, oldVal) { // watch it
                this.selectedValue = newVal;
            }
        },
        created(){

        },
        computed :{
            isRequired(){
                return this.item.required == true ? 'required' : ''
            },
            values(){
                if (this.item.values[0] && typeof this.item.values[0] == 'object'){
                    var values = {};

                    for (const [key, value] of Object.entries(this.item.values)) {
                       for (const [key1, value1] of Object.entries(value)) {
                            values[key1] = value1;
                        }
                    }
                    return values;
                }
                else{
                    return this.item.values;
                }
            }

        },
        methods: {
            handleClick () {
            },
            updateInput () {
                this.$emit('input', event.target.value)
            },
            getValue(value, index){
                if(this.useIndex){
                    return index;
                }
                return value;
            }
        }
    }
</script>

<style scoped>
   .input-group.radio .form-control{
        height: auto;
   }
</style>