<template>
  <div>
      <div class="card-header border-0">
        <div  :class="{ 'active': closed }"  aria-haspopup="true" aria-expanded="false">
          <h4>
            <span
              class="badge text-capitalize"
              :class="{
                'badge-success': surgery.status === 'active',
                'badge-info': surgery.status === 'completed',
                'badge-danger': surgery.status === 'past due',
                'badge-warning': surgery.status === 'canceled'
              }"
            >
              {{ surgery.status }}
            </span>
             {{ surgery.date | formatDate }} - {{ surgery.template_name }}
             <br>
            <a
             class="collapse-link"
              data-toggle="collapse"
              :data-target="'#collaspeSurgery' + surgery._id"
              @click="closed = !closed"
            >
             <span v-if="!closed">Show Detail</span>
              <span v-else>Hide Detail</span>
            </a>
          </h4>
        </div>
      </div>
      <div class="card-body collapse in" :id="'collaspeSurgery' + surgery._id">
        <div class="row mb-2">
          <div class="col-lg-6">
            <label>Notes:</label>
            <p class="paragraphBreak">{{ surgery.notes }}</p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>By</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="procedure, index in surgery.schedule" :key="procedure.id" v-if="procedure.type === 'surgery'">
                    <td>{{ procedure.date | formatDate }}</td>
                    <td
                      class="text-capitalize"
                      v-if="procedure.status === 'pending' && pastDue(procedure)"
                    >
                      <span class="badge badge-danger">Past Due</span>
                    </td>
                    <td v-else class="text-capitalize">
                      <span
                        class="badge"
                        :class="{
                          'badge-primary': procedure.status === 'pending',
                          'badge-info': procedure.status === 'completed',
                          'badge-danger': procedure.status === 'past due',
                          'badge-warning': surgery.status === 'canceled'
                        }"
                      >
                        {{ procedure.status }}
                      </span>
                    </td>
                    <td>{{ procedure.by || '-' }}</td>
                    <td>{{ procedure.notes || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="surgery.schedule_followup">
          <SurgeryFollowupDates
            :surgery="surgery"
            :key="'surgerytFollowupDates' + componentKey"
          ></SurgeryFollowupDates>
        </div>
      </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import SurgeryFollowupDates from './surgery-followup-dates.vue';

  export default {
      name: 'surgery',

      components: {
        SurgeryFollowupDates,
      },
        props : {
          surgery : {
              type : Array|Object,
              required :true,
          },
      },
      data: function () {
          return { 
            componentKey :1,
            closed : false,
          }
      },
      methods : {
          pastDue (result){
              return moment.unix(result.date/1000).endOf('day').unix() < moment().unix();
          },
      }
  } 

</script>
<style scoped>
.collapse-link{
        cursor: pointer;
        font-size: 12px;
        margin-top: 10px;
        
    }
.card-header h5 {
    text-wrap: balance;
}
.active::after {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.dropdown-toggle:not(.dropdown-toggle-empty)::after {
    padding-right: 14px;
}

</style>