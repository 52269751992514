<template>
  <div
    :id="'weight_add_modal_' + animal_id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="id"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Add New Weight Info</h3>
          <button
            ref="CloseBtn"
            type="button"
            class="btn btn-xs btn-icon btn-soft-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            <svg
              aria-hidden="true"
              width="10"
              height="10"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
              />
            </svg>
          </button>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitWeight)">
            <div class="modal-body">
              <BaseEditDatePickerField
                v-model="values.date"
                :item="{ label: 'Date', required: true }"
              />
              <BaseEditTextField
                v-model="values.weight"
                :item="{ label: 'Weight', required: true }"
              />
              <BaseEditSelectField
                v-model="values.unit"
                :item="{ label: 'Unit', required: true, values: weightUnits }"
              />
              <p v-if="error" class="text-danger">{{ error }}</p>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-white btn-sm" data-dismiss="modal">
                Cancel
              </button>
              <button type="subumit" class="btn btn-primary btn-sm">
                Save
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'

export default {
  name: 'AddWeightModal',
  components: { ValidationObserver },
  directives: {},
  props: {
    animal_id: {
      type: String,
    },
    
  },
  data() {
    return {
      values: {
        date: new Date(),
        weight: null,
        unit: 'lbs',
      },
      weightUnits: ['lbs' , 'oz', 'kg', 'g'],
      error: null,
    }
  },
  mounted() {},
  computed: {},
  methods: {
    onSubmit() {
      const pickedDate = moment(this.values.date, 'YYYY-MM-DD')
      console.log('weight',this.weight )
      if (!this.weight ){
        console.log('no weight', this.weight)
          this.submitWeight()
      }
      else if (this.animal_id && (this.values.weight !== this.weight.weight || this.values.unit !== this.weight.unit || !pickedDate.isSame(moment(this.weight.date), 'day') ) ) 
      {
        this.error = null
        console.log('this.weight', this.weight)
        const data = {
          weights: {
            ...this.values,
            date: pickedDate.toDate(),
            ...(this.weight && pickedDate.isSame(moment(this.weight.date), 'day') ? { weight_id: this.weight.weight_id } : {}),
          },
        }
        console.log('data', data)
        this.$store
          .dispatch('updateAnimal', { animal_id: this.animal_id, data })
          .then((response) =>{
            this.$emit('updateChart');
            this.$refs.CloseBtn.click()
          } )
          .catch((err) => {
            console.log(err)
            this.error = err.response.data
          })
      } else {
        this.$emit('updateChart');
        this.$refs.CloseBtn.click()
      }
    },
    submitWeight(){
        this.error = null

      const data = {
        weights: {
          ...this.values,
        },
      }

      this.$store
        .dispatch('updateAnimal', { animal_id: this.animal_id, data })
        .then((response) => {
            this.$emit('updateChart');
            this.$refs.CloseBtn.click()
          } )
        .catch((err) => {
          console.log(err)
          this.error = err.response.data
      })
      this.$emit('updateChart')
    }
  }
}
</script>

<style lang="scss" scoped></style>
