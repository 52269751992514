<template>
    <div class="m-t-xs" @click="$emit('editWeight',weight)"
        data-toggle="modal"
        :data-target="'#weight_edit_modal_' + animal_id"
        data-backdrop="static"
        data-keyboard="false"
        @mouseover="visible = true"
        @mouseleave="visible = false"
    >
        <span class="weight p-1" >
            {{weight.date | formatDate}} - {{convertedWeight}} {{displayUnit}}
        </span>
        <hr>
    </div>
</template>

<script>
    export default {
        props: ['animal_id', 'weight', 'index', 'unit'],
        components : {
        },
        data() {
            return {
                visible : false,
                displayUnit : 'lbs',
            }
        },
        created() {

        },
        mounted() {

        },
        computed : {
            convertedWeight(){
                if(this.unit == 'lbs'){
                    if( this.weight.unit == 'kg')
                    {
                        this.displayUnit = 'lbs';
                        return (this.weight.weight / 0.453592).toFixed(3)
                    }
                    else if(  this.weight.unit == 'g') //grams to lbs
                    {
                        this.displayUnit = 'lbs';
                        return (this.weight.weight * 0.0022046).toFixed(3)
                    }
                    else if(  this.weight.unit == 'oz')
                    {
                        this.displayUnit = 'oz';
                        return (this.weight.weight)
                    }
                    else
                    {
                        this.displayUnit = 'lbs';
                        return this.weight.weight
                    }
                }

                if(this.unit == 'kg'){
                    if( this.weight.unit == 'lbs')
                    {
                        this.displayUnit = 'kg';
                        return (this.weight.weight * 0.453592).toFixed(3)
                    }
                    else if(this.weight.unit == 'oz')
                    {
                        this.displayUnit = 'kg';
                        return (this.weight.weight * 0.0283495).toFixed(3)
                    }
                    else if(this.weight.unit == 'g')
                    {
                        this.displayUnit = 'g';
                        return this.weight.weight
                    }
                    else
                    {
                        this.displayUnit = 'kg';
                        return this.weight.weight
                    }
                }
            },
        },
        methods: {
            clickWeight(weight){
                this.$emit('editWeight',weight);

            }
        },
    }
</script>
<style scoped>
.weight:hover{
        border:1px solid rgb(197, 195, 195);
        cursor: pointer;
    }
</style>