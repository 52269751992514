<template>
  	<div id="app">
		<ModalIdle v-if="isIdle && isLoggedIn" />
    	<router-view />
  	</div>
</template>

<script>

import ModalIdle from "./components/ModalIdle.vue"
import { authComputed } from './store/helpers.js'

export default {
	components: {ModalIdle},
	computed: {
		...authComputed,
		isIdle() {
			if(this.$store.state.idleVue.isIdle){
				console.log('isIdle', this.$store.state.idleVue.isIdle)
				return 'expired'
			}
			return ""
			
		}
	},
	created: function () {
		this.$http.interceptors.response.use(undefined, function (err) {
			return new Promise(function () {
				if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
					this.$store.dispatch('logout')
					this.$router.push('/login')
				}
				throw err
			})
		})
	},
	methods : {
		logout() {
			this.$store.dispatch('logout')
			this.$router.push('/login')
		}
	}
}
</script>

<style lang="scss">
//@import './assets/scss/theme';
//@import './assets/scss/themes/default';
//@import './assets/scss/user-variables';
//@import './assets/scss/front/variables';
//@import './assets/scss/front/front';
//@import './assets/scss/user';
</style>

<style>
@import './assets/css/theme.css';
</style>
