<template>
    <ValidationProvider :vid="item.name" name="microchip" rules="validChip" v-slot="v">
        <div class="row form-group">
            <label class="col-form-label control-label" :class="[labelSize, {'text-danger' : v.errors[0] || error}]" >{{label}}
                <span v-if="!item.required" class="input-label-secondary">(Optional)</span>
            </label>
            <div :class="inputSize" >
                <input 
                    class="form-control" 
                    :class="{'is-invalid' : v.errors[0] || error}"
                    :type="item.type" 
                    :name="item.name" 
                    v-model="selectedValue" 
                    @click="handleClick"
                    @input="$emit('input', $event.target.value)"
                    :disabled="disabled"
                    >
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                <span v-if="error" class="text-danger font-size-1">{{error}}</span>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>

    import { ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

     extend('validChip', value => {
        const length = value.length;
        if (length == 9 || length == 10 || length == 15){
            return true
        }
        return 'The microhip must be 9, 10 or 15 alphanumeric characters'
    });

    export default {
        components : {
            ValidationProvider
        },
        props : {
            id : {
                type : String,
                default : "text_"
            },
            item :{
                type : Object,
                required : true,
            },
            value : {
                type : [String, Number],
                required :false,
            },
            error : {
                type : String,
                required : false,
                default : null
            },
            labelSize: {
                type : String,
                default : 'col-sm-3'
            },
            inputSize: {
                type : String,
                default : 'col-sm-9'
            },
            disabled : {
                type : String,
                default : null
            }
        },
        data () {
            return {
                selectedValue : this.value,
                label : this.item.label
            }
        },
        computed : {
            isRequired(){
                return this.item.required == true ? 'required' : ''
            },
        },
        watch: { 
            value: function(newVal, oldVal) { // watch it
                this.selectedValue = newVal;
            }
        },
        created(){
            
        },
        methods: {
            handleClick () {
                this.$emit('cleanError', this.item.name);               
            },
        }
    }
</script>

<style scoped>
   .input-group.text .form-control{
        height: auto;
   }
</style>