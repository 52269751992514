<template>
<div>
    <div :id="id" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="id" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <ValidationObserver v-slot="{ handleSubmit }">
            <form class="" @submit.prevent="handleSubmit(onSubmit)">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="id">{{title}}</h5>
                        <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                            </svg>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <center>
                                    <vue-qrcode 
                                        :value="adoptAppUrl+'/animal/'+animal.shelter_id+'/'+animal._id"
                                    > 
                                    </vue-qrcode>
                                    <p class="wrap_url">
                                        <a :href="adoptAppUrl+'/animal/'+animal.shelter_id+'/'+animal._id" target="_blank">
                                            <span id="adopt_url_link">{{adoptAppUrl}}/animal/{{animal.shelter_id}}/{{animal._id}}</span>
                                        </a>
                                    </p>
                                </center>
                                <hr>
                                <h4 class="mt-4 mb-4">Send a Text Message or Email with a link to {{animal.animal_name}}'s profile.</h4>
                                
                                <ValidationProvider :rules="{ regex:/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ }" v-slot="v">
                                <div class="form-group">
                                    <label for="phone" class="control-label">Mobile Phone:</label>
                                    <input type="text"
                                        name="phone" 
                                        id="phone"
                                        class="form-control" 
                                        v-model="phone"
                                        placeholder="555-555-5555"
                                        aria-label="Mobile Phone"
                                        data-msg="Please enter a valid mobile phone number."
                                        v-mask="[
                                        '(###) ###-####',
                                        '#(###) ###-####', 
                                        '##(###) ###-####', 
                                        '###(###) ###-####', 
                                        '#(##) ####-####', 
                                        '##(##) ####-####', 
                                        '###(##) ####-####']"
                                    >
                                    <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                                </div>
                                </ValidationProvider>
                                <p>Or</p>
                                <ValidationProvider rules="email" v-slot="v">
                                <div class="form-group">
                                    <label for="email" class="control-label">Email Address:</label>
                                    <input type="email"
                                        name="email" 
                                        id="email"
                                        class="form-control" 
                                        v-model="email"
                                        placeholder="adopter@gmail"
                                        aria-label="Email address"
                                        data-msg="Please enter a valid email address."
                                    >
                                    <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <center><span v-if="errorMsg" class="text-danger font-size-1">{{errorMsg}}</span></center>
                        <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn text-capitalize" :disabled="processing" :class="color">
                            <span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{action}}
                        </button>
                    </div>
                </div>
            </form>
            </ValidationObserver>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import config from '../../../store/config'
import VueQrcode from 'vue-qrcode'
import { mask } from 'vue-the-mask'

import { ValidationProvider, extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";

extend("regex", regex);

export default {
    name: 'EmailAdopter',
    components: { VueQrcode },
    directives: { mask },
    props: {
		id: {
			type: String,
			required: true,
		},
        title: {
			type: String,
			required: true,
		},
        action: {
			type: String,
            default : "Send"
		},
        color: {
			type: String,
			default : 'btn-primary'
		},
        animal: {
            type : Object,
            required : true
        },
        index: {
			type: String,
			required: true,
		},
	},
    data() {
        return {
            email : '',
            phone : '',
            adoptAppUrl : `${config.ADOPT_APP_URL}`,
            processing : false,
            errorMsg : null,
            error : null,
        };
    },
    mounted() {
    },
    computed :{
        ...mapState(['user']),

    },
    methods: {
        async onSubmit(){
            if (this.email == '' && this.phone == ''){
                this.errorMsg = "Please add a valid email or mobile phone number"
                return
            }
            
            this.processing = true
            let data = {}
            data.animal_id = this.animal._id
            data.animal_name = this.animal.animal_name
            data.shelter_id = this.animal.shelter_id
            data.email = this.email
            data.phone = this.phone

            await this.$store.dispatch('emailAdoptLink', data)
            .then(response => {
                this.processing = false
                if (!response.data.error){
                    $("#"+this.id).modal("hide");
                    this.showToaster('success')
                    this.processing = false
                    this.errorMsg = null
                }
                else{
                    this.processing = false
                    this.errorMsg = response.data.error
                }
            })
            .catch(error => {
                this.error = error
                this.showToaster('error', error)
                this.processing = false
                this.errorMsg = error.data
                //$("#"+this.id).modal("hide");
            })
        },
        showToaster(messages, err){
			if(messages){
                if (messages == "success"){
                    this.$toasted.success('Adoption Link Sent', { 
                        theme: "bubble", 
                        position: "top-center", 
                        duration : 3000,
                        className : 'center',
                        containerClass : 'center'
                    });
                }
                if (messages == "error"){
                    this.$toasted.error('Adoption Link was not sent successfully', { 
                        theme: "bubble", 
                        position: "top-center", 
                        duration : 3000,
                        className : 'center',
                        containerClass : 'center'
                    });
                }
			}		
		}
    },
};
</script>

<style lang="scss" scoped>

</style>