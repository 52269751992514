export const MICROCHIP_ISSUERS = [
  '24PetWatch',
  '911PetChip & Free Pet Chip Registry',
  'ACA MARRS',
  'AKC Reunite',
  'AVID',
  'BC Pet Registry',
  'Banfield',
  'Bayer resQ',
  'BeKind PetFind',
  'BuddyID',
  'EIDAP',
  'Fi',
  'Found Animals',
  'Furreka',
  'HomeAgain',
  'Homeward Bound Pet',
  'Identrac Inc.',
  'InfoPET',
  'International Pet Registry',
  'Microchip I.D. Solutions',
  'MyPetsChip',
  'Nanochip ID Inc.',
  'National Animal Identification Center',
  'Peeva',
  'PetKey',
  'PetLink',
  'Petstablished',
  'Prime Trackr',
  'Save This Life',
  'SmartTag Microchip',
  'uPet'
]
