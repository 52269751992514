<template>
<div>
    <div class="container space-top-2">
		<div class="row align-items-center mb-5">
			<div class="col-sm mb-3 mb-sm-0">
				<h2 class="h2 mb-0">Videos</h2>
                <small v-if="user.foster_edit_videos != false">Add your YouTube or Vimeo videos</small>
			</div>
			<div class="col-sm-auto" v-if="user.foster_edit_videos != false">
				<div class="btn-group">
					<button class="btn btn-primary btn-xs" type="button" 
                        data-toggle="modal" 
                        data-target="#addVideo" 
                        aria-haspopup="true" 
                        aria-expanded="false"
                        @click="showModal()">
						+ Video Url
					</button>
				</div>
			</div>
		</div>

        <div class="row align-items-center mb-5">
			<div class="col-sm col-md col-lg-6 mb-3 mb-sm-0">
                <div v-if="animal.videos && animal.videos.length > 0">
                    <draggable
                        :list="animal.videos"
                        class="list-group m-t-sm"
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="onEnd"
                    >
                    <div class="m-5" v-for="(video, index) in animal.videos" :key="index">
                        <span class="vertical-align-topx pull-leftx m-md-2">
                            <font-awesome-icon icon="bars"></font-awesome-icon >
                        </span>
                        <span class="badge-deletex m-md-2" v-if="user.foster_edit_videos != false">
                            <font-awesome-icon class="text-danger" icon="trash" 
                            data-toggle="modal" 
                            :data-target="'#deleteVideo'+index" 
                            @click="DeleteVideoModal(video.video_link, index)"></font-awesome-icon>
                        </span>
                        <video-embed css="embed-responsive-16by9 mt-3" :src="video.video_link"></video-embed>
                    </div>
                    </draggable>
                    <hr class="my-10" />
                </div>
                <div v-else >
                    <h3><span v-if="animal.animal_name">{{animal.animal_name}}</span><span v-else>Unknown</span> does not have any videos.</h3>
                </div>
            </div>
        </div>
	</div>
	<AddVideoModal 
        v-if="showFormModal"
		:id="'addVideo'"
        :key="'form'+formKey"
		:title="'Add Video URL'"
		:animal="animal"
        @updateDetails="$emit('updateDetails')"
    > 
    </AddVideoModal>

    <DeleteVideoModal
    v-if="showDeleteModal"
		:id="'deleteVideo'+this.index"
        :key="'deleteVideo'+formKey"
		:title="'Delete Video URL'"
        :action="'delete'"
		:animal="animal"
        :video_url="video_url"
        @updateDetails="$emit('updateDetails')"
    >
    </DeleteVideoModal>
</div>
</template>
    
    <script>
    import { mapState } from 'vuex'
    import { authComputed } from '../../../store/helpers.js'
    import AddVideoModal from './AddVideoModal.vue'
    import DeleteVideoModal from './DeleteVideoModal.vue'
    import draggable from 'vuedraggable'

    export default {
        name: 'Video',
        components: {AddVideoModal, DeleteVideoModal, draggable},
        props: {
        },
        data() {
            return {
                formKey : 1,
                form_id : null,
                showFormModal: false,	
                showDeleteModal : false,
                video_url : '',
                index : '',
            }
        },
        computed: {
            ...mapState(['user', 'animal']),
            ...authComputed,
        },
        methods: {
            showModal(){
                this.formKey += 1;
                this.showFormModal = true;
            },
            DeleteVideoModal(video_url, index){
                this.formKey += 1;
                this.showDeleteModal = true;
                this.index = index
                this.video_url = video_url
            },
            onEnd() {
                let data = {
                    videos: this.animal.videos
                };
                this.dragging = false;
                this.$store.dispatch('sortVideos', {animal_id: this.animal._id, videos: this.animal.videos})
                    .then(response => {
                        this.showToaster('success', 'Videos Successfull Sorted')
                        this.$emit('updateDetails')
                    }).
                    catch(errro => {
                        this.showToaster('error', 'There was an error removing the video')
                    })
                
            },
        }
       
    }
    </script>
    <style>
    .badge-delete {
        position: relative;
        top: 25px;
        left: -25px;
        font-size: 17px;
        background : #fff;
        color : #3c3c3c;
    }
    .vertical-align-top {
        vertical-align: text-top;
    }
    </style>