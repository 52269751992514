<template>
	<div class="container p-0 space-top-2">
		<div class="row col-12 mb-3  m-0">
			<div v-if="animal.medicalMedications.length == 0"> 
				<div class="col-lg-12 text-center m-b-md"> You do not have any Medications </div>
			</div>       
			<div v-else class="card card-bordered card-hover-shadow mb-5 pt-3 pl-3 float-e-margins p-0 col-12" v-for="(medication, index) in animal.medicalMedications" :key="medication.id">
				<Medication 
					:medication="medication" 
					:index="index"
					> 
				</Medication>
			</div>
		</div>
	</div>
</template>

<script>
import { authComputed } from '../../../../store/helpers.js'
import Medication from './Medication'
import { mapState } from 'vuex'
export default {
	name: 'Medications',
	components: {Medication},
	data() {
		return {
			
		}
	},
	computed: {
        ...mapState({
			user: 'user',
			animal: state => state.animal || {},
		}),
		...authComputed,
		
	},
}
</script>


<style  scoped>
.col-lg-6{
	padding:10px;
}
.col-lg-6 hr{
		margin: 5px;
}
.row{
    font:menu;
}
.card{
    min-height: 131px;
}
</style>