<template>
    <ValidationProvider :vid="item.name" :rules="isRequired" v-slot="v">
        <div class="row form-group">
            <label class="col-form-label input-label" :class="[labelSize, {'text-danger' : v.errors[0]}]">{{label}}
                <span v-if="!item.required" class="input-label-secondary">(Optional)</span>
            </label>
            <div :class="inputSize">
                <div class="form-control check">
                    <div class="custom-control custom-checkbox" v-for="(value, index) in values" :key="index">
                        <input type="checkbox"
                            class="custom-control-input" 
                            :class="{'is-invalid' : v.errors[0]}"
                            :name="item.name+'_'+index" 
                            :value="getValue(value, index)" 
                            v-model="selectedValue" 
                            :id="id+'_'+item.name+'_'+index" 
                            @change="updateInput" 
                            @click="handleClick"
                            :disabled="disabled"
                        >
                        <label class="custom-control-label" :for="id+'_'+item.name+'_'+index">{{value}}</label>
                    </div>
                </div>
                <span v-if="v.errors[0]" class="text-danger font-size-1">{{v.errors[0]}}</span>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>

    import { ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        components : {
            ValidationProvider,
        },
        props : {
            id : {
                type : String,
                default : "checkbox_"
            },
            item :{
                type : Object,
                required : true,
            },
            value : {
                type : Array,
                required :false,
            },
            error : {
                type : String,
                required : false,
                default : null
            },
            labelSize: {
                type : String,
                default : 'col-sm-3'
            },
            inputSize: {
                type : String,
                default : 'col-sm-9'
            },
            useIndex: {
                type : Boolean,
                default: false
            },
            disabled : {
                type : String,
                default : null
            }
        },
        data () {
            return {
                checkedName : this.value || [],
                selectedValue : this.value || [],
                label : this.item.label,
            }
        },
        created(){
            
        },
        watch: { 
            value: function(newVal, oldVal) { // watch it
                this.selectedValue = newVal;
            }
        },
        computed :{
            isRequired(){
                return this.item.required == true ? 'required' : ''
            },
            values(){
                if (this.item.values[0] && typeof this.item.values[0] == 'object'){
                    var values = {};
                    for (const [key, value] of Object.entries(this.item.values)) {
                       for (const [key1, value1] of Object.entries(value)) {
                            values[key1] = value1;
                        }
                    }
                    return values;
                }
                else{
                    return this.item.values;
                }
            },
        },
        methods: {
            handleClick () {
                this.$emit('cleanError', this.item.name);               
            },
            updateInput(event) {
                let isChecked = event.target.checked
                if (isChecked) {
                    this.checkedName.push(event.target.value)
                } 
                else{
                    this.checkedName.splice(this.checkedName.indexOf(event.target.value), 1)
                }

               this.$emit('input', this.checkedName);  
            },
            getValue(value, index){
                if(this.useIndex){
                    return index;
                }
                return value;
            }
        }
    }
</script>

<style scoped>
    .form-control.check{
        height: auto;
   }
   .custom-control-input[disabled] .custom-control-label, 
   .custom-control-input:disabled .custom-control-label {
         color: #1a1b1b !important; 
    }
    input[type=checkbox][disabled]{
        outline:1px solid red;
    }
</style>