<template>
  <div>
      <div class="card-header border-0">
        <div class="row">
            <div :class="{ 'active': closed }"  aria-haspopup="true" aria-expanded="false">
              <h4>
              <span
                class="badge text-capitalize"
                :class="{
                  'badge-success': medication.status === 'active',
                  'badge-info': medication.status === 'completed',
                  'badge-danger': medication.status === 'past due',
                  'badge-warning': medication.status === 'canceled'
                }"
              >
                {{ medication.status }}
              </span>
              {{ medication.date | formatDate }} - {{ medication.template_name }}
              <br>
              <a
              class="collapse-link"
                data-toggle="collapse"
                :data-target="'#collapseMedication' + medication._id"
                @click="closed = !closed"
              >
              <span v-if="!closed">Show Detail</span>
                <span v-else>Hide Detail</span>
              </a>
            </h4>
            </div>
        </div>
        </div>
        <div class="card-body collapse in" :id="'collapseMedication' + medication._id">
            <div >
                <div class="row p-2 ">
                <div class="col-lg-4"><strong>Start Date:</strong></div>
                <div class="col-lg-8">
                    <span v-if="medication.start_date">{{ medication.start_date | formatDate }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row p-2 " v-if="medication.reason">
                <div class="col-lg-4"><strong>Reason for Treatment:</strong></div>
                <div class="col-lg-8">
                    <span class="pre-line" v-if="medication.reason">{{ medication.reason }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Route:</strong></div>
                <div class="col-lg-8">
                    <span v-if="medication.route">{{ medication.route }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Amount:</strong></div>
                <div class="col-lg-8">
                    <span v-if="medication.amount">{{ medication.amount }} {{ medication.amount_unit }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Frequency:</strong></div>
                <div class="col-lg-8">
                    <span v-if="medication.frequency">{{ medication.frequency }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Duration:</strong></div>
                <div class="col-lg-8">
                    <span v-if="medication.duration">{{ medication.duration }} {{ medication.duration_unit }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Doses:</strong></div>
                <div class="col-lg-8">
                    <span v-if="medication.doses">{{ medication.doses }} Doses</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Notes:</strong></div>
                <div class="col-lg-8">
                    <span class="pre-line" v-if="medication.notes">{{ medication.notes }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 ">
                <div class="col-lg-4"><strong>Attending vet:</strong></div>
                <div class="col-lg-8">
                    <span class="pre-line" v-if="medication.attending_vet">{{ medication.attending_vet }}</span>
                    <span v-else>-</span>
                </div>
                </div>
                <div class="row  p-2 " v-if="medication.label">
                <div class="col-lg-4"><strong>Medication Label:</strong></div>
                <div class="col-lg-8">
                    <div class="border p-1">
                    <span v-html="medication.label"></span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'medication',

  props: {
    medication: {
      type: Array | Object,
      required: true,
    },
  },
  data: function () {
    return {
      closed:  false,
    };
  },
};
</script>

<style scoped>
.collapse-link{
        cursor: pointer;
        font-size: 12px;
        margin-top: 10px;    
}
.card-header h4 {
    text-wrap: balance;
}

.pre-line {
  white-space: pre-line;
}

.active::after {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.dropdown-toggle:not(.dropdown-toggle-empty)::after {
    padding-right: 14px;
}

</style>