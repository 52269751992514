<template>
<div>
    <div :id="id" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="id" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" :id="id">{{form.form_title}}</h3>
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                        <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                        </svg>
                    </button>
                </div>
                <ValidationObserver ref="medicalForm" v-slot="{handleSubmit}">
                    <form  class="form-horizontal" 
                            id="form" 
                            enctype='multipart/form-data' 
                            action='add' 
                            @submit.prevent="handleSubmit(onSubmit)"
                        >
                        <div class="modal-body">
                            <h4 class="mb-3">Enter Medical Data Below</h4>
                            <div class="col-lg-12">
                                <BaseEditDatePickerField
                                    v-model="exam_date"
                                    :item="examDateField"
                                    labelSize="col-lg-12"
                                    inputSize="col-lg-12"
                                />
                            </div>
                            <AddMedicalFields
                                :form="form"
                                :exam="exam"
                            >
                            </AddMedicalFields>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-white" data-dismiss="modal">Discard</button>
                            <button type="subumit" class="btn" :class="color">Submit</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import AddMedicalFields from './AddMedicalFields'
	import { ValidationObserver } from 'vee-validate';
    import _ from 'lodash';
    import moment from 'moment-timezone'

export default {
    name: 'EditExamModal',
    components: { AddMedicalFields, ValidationObserver },
    directives: {  },
    props: {
		id: {
			type: String,
			required: true,
		},
        form_id : {
            type : String,
            required : true
        },
        title: {
			type: String,
			required: true,
		},
        color: {
			type: String,
			default: 'btn-primary',
		},
        animal : {
            type : Object,
            required : true,
        }
	},
    data() {
        return {
            form : {},
            exam : {},
            exam_date : new Date(),
            examDateField : {
                "label" : "Exam Date", 
                "type" : "date", 
                "name" : "exam_date", 
                "required" : true, 
            }
        };
    },
    mounted() {
        this.getForm()
    },
    computed :{
    },
    methods: {
        async getForm(){
            this.$store.dispatch('getMedicalForm', this.form_id)
            .then(response => {
                console.log('medical form', response.data)
                this.form = response.data
            })
            .catch((err) => console.log(err));
        },
        onSubmit(){
            this.submit();
        },
        async submit() {
            console.log('exam_date', this.exam_date)
            this.exam.shelter_id = this.animal.shelter_id
            this.exam.animal_id = this.animal._id
            this.exam.form_id = this.form._id
            this.exam.exam_date = moment(this.exam_date)
            console.log('submit info', this.exam)

            this.$store.dispatch('addMedical', this.exam)
            .then(response => {
                console.log('this.id', this.id)
                $("#"+this.id).modal("hide");
            })
            .catch((err) => console.log(err));

        },    
    }   
}
</script>

<style lang="scss" scoped>

</style>