import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, min, confirmed } from 'vee-validate/dist/rules';
import IdleVue from "idle-vue";
import 'bootstrap'
import "./filters/filters"
import './assets/js/hs.core.js'

import GlobalMixin from './mixins/globalmixin'
Vue.mixin(GlobalMixin)

import _ from 'lodash'
Vue.prototype._ = _

import Grid from 'vue-js-grid'
Vue.use(Grid)

import Toasted from 'vue-toasted';
Vue.use(Toasted)

import Embed from 'v-video-embed'
Vue.use(Embed);

import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faTimes, faTrash, faImage, faFile, faCheck, faPrint, faBars, faComments, faUser } from '@fortawesome/free-solid-svg-icons'

library.add(faTimes)
library.add(faTrash)
library.add(faImage)
library.add(faFile)
library.add(faCheck)
library.add(faPrint)
library.add(faComments)
library.add(faUser)
library.add(faBars)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// define global vee-validate rules
extend('required', {
	...required,
	message: 'The {_field_} field is required.'
});
extend('email', {
	...email,
	message: 'The email address is not valid.'
});
extend('min', {
	...min,
	params: ['length'],
	message: 'The {_field_} field must have at least {length} characters.'
});
extend('confirmed', {
	...confirmed,
	message: 'The {target} does not match.'
});

// Global load Base Components
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
	// The relative path of the components folder
	'./components/base-components',
	// Whether or not to look in subfolders
	false,
	// The regular expression used to match base component filenames
	/[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
	// Get component config
	const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  	const componentName = upperFirst(
		camelCase(
		// Gets the file name regardless of folder depth
		fileName
			.split('/')
			.pop()
			.replace(/\.\w+$/, '')
		)
  	)
  // Register component globally
	Vue.component(
		componentName,
		// Look for the component options on `.default`, which will
		// exist if the component was exported with `export default`,
		// otherwise fall back to module's root.
		componentConfig.default || componentConfig
	)
})

Vue.prototype.$http = axios
const user = localStorage.getItem('user')

if (user) {
  	Vue.prototype.$http.defaults.headers.common['Authorization'] = user.token
}

const eventsHub = new Vue();
Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	store,
	idleTime: 3600000, // 60 minutes
	startAtIdle: false,
});

Vue.config.productionTip = false

new Vue({
	router,
	store,
	created () {
		const userString = localStorage.getItem('user') // grab user data from local storage
		if (userString) { // check to see if there is indeed a user
			const userData = JSON.parse(userString) // parse user data into JSON
			this.$store.commit('SET_USER_DATA', userData) // restore user data with Vuex
		}
		axios.interceptors.response.use(
			response => response, // simply return the response 
			error => {
				if (error.response && error.response.status === 401) { // if we catch a 401 error
					this.$store.dispatch('logout') // force a log out 
				}
				return Promise.reject(error) // reject the Promise, with the error as the reason
			}
		)
	}, 
  	render: (h) => h(App),
}).$mount('#app')
