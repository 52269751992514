var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-header border-0"},[_c('div',{class:{ 'active': _vm.closed },attrs:{"aria-haspopup":"true","aria-expanded":"false"}},[_c('h4',[_c('span',{staticClass:"badge text-capitalize",class:{
              'badge-success': _vm.surgery.status === 'active',
              'badge-info': _vm.surgery.status === 'completed',
              'badge-danger': _vm.surgery.status === 'past due',
              'badge-warning': _vm.surgery.status === 'canceled'
            }},[_vm._v(" "+_vm._s(_vm.surgery.status)+" ")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.surgery.date))+" - "+_vm._s(_vm.surgery.template_name)+" "),_c('br'),_c('a',{staticClass:"collapse-link",attrs:{"data-toggle":"collapse","data-target":'#collaspeSurgery' + _vm.surgery._id},on:{"click":function($event){_vm.closed = !_vm.closed}}},[(!_vm.closed)?_c('span',[_vm._v("Show Detail")]):_c('span',[_vm._v("Hide Detail")])])])])]),_c('div',{staticClass:"card-body collapse in",attrs:{"id":'collaspeSurgery' + _vm.surgery._id}},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-lg-6"},[_c('label',[_vm._v("Notes:")]),_c('p',{staticClass:"paragraphBreak"},[_vm._v(_vm._s(_vm.surgery.notes))])])]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.surgery.schedule),function(procedure,index){return (procedure.type === 'surgery')?_c('tr',{key:procedure.id},[_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(procedure.date)))]),(procedure.status === 'pending' && _vm.pastDue(procedure))?_c('td',{staticClass:"text-capitalize"},[_c('span',{staticClass:"badge badge-danger"},[_vm._v("Past Due")])]):_c('td',{staticClass:"text-capitalize"},[_c('span',{staticClass:"badge",class:{
                        'badge-primary': procedure.status === 'pending',
                        'badge-info': procedure.status === 'completed',
                        'badge-danger': procedure.status === 'past due',
                        'badge-warning': _vm.surgery.status === 'canceled'
                      }},[_vm._v(" "+_vm._s(procedure.status)+" ")])]),_c('td',[_vm._v(_vm._s(procedure.by || '-'))]),_c('td',[_vm._v(_vm._s(procedure.notes || '-'))])]):_vm._e()}),0)])])])]),(_vm.surgery.schedule_followup)?_c('div',[_c('SurgeryFollowupDates',{key:'surgerytFollowupDates' + _vm.componentKey,attrs:{"surgery":_vm.surgery}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("By")]),_c('th',[_vm._v("Notes")])])])
}]

export { render, staticRenderFns }