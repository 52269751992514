<template>
    <div class="container p-0 space-top-2">
        <div class="row col-12 mb-3 m-0">
            <div v-if="animal.medicalSurgeries.length == 0"> 
                <div class="col-lg-12 text-center m-b-md"> You do not have any Surgeries </div>
            </div>  
            <div v-else class="card card-bordered card-hover-shadow mb-5 pt-3 pl-3 float-e-margins p-0 col-12" v-for="(surgery, index) in animal.medicalSurgeries" :key="surgery._id">
                <Surgery 
                    :surgery="surgery" 
                > 
                </Surgery>
            </div>
        </div>
    </div>
</template>

<script>
    import Surgery from './surgery.vue'
    export default {
        name: 'medical_surgeries',

        components: {
            Surgery,
        },
         props : {
            animal : {
                type : Array|Object,
                required :true,
            },
        },
        data: function () {
            return { 

            }
        },
    } 
</script>

<style scoped>

.card{
    min-height: 131px;
}
</style>