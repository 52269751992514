<template>
<div>
	<div class="collapse multi-collapse" :id="id">
		<div class="d-sm-flex">
			<div class="media-body">
				<div class="row d-flex flex-row-reverse ">
					<div class="col-12 col-md-2 mt-3 mt-md-0">
						<div class="d-flex justify-content-between mb-5">
							<button class="btn btn-xs btn-success" v-if="exam.form.foster_app" data-toggle="modal" :data-target="'#editExamModal_'+exam._id" @click="editModal">Edit</button>
							<button class="btn btn-xs btn-danger" data-toggle="modal" :data-target="'#deleteExamModal_'+exam._id" @click="deleteModal">Delete</button>
							
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-md-12">
						<div v-for=" field, index in exam.form.fields" :key="index">
							<div v-if="field.customField || field.medicalField">
								<BaseHeader
									v-if="field.type == 'header'"
									:item="field"
									headerSize="h2"
									classSize="col-lg-12"
								/>
								<BaseSeperator
									v-if="field.type == 'seperator'"
									:classSize="'col-lg-12'"
								/>
								<BaseParagraph
									v-if="field.type == 'paragraph'"
									classSize="col-lg-12"
									:item="field"
								/>
								<BaseEditTextField
									v-if="field.type == 'text' || field.type == 'number' || field.type == 'email'"
									v-model="exam[field.name]"
									:id="'text_view_'+field.name"
									:item="field"
									:labelSize="labelSize"
									:inputSize="inputSize"
									:disabled="disabled"
								/>
								<BaseEditSelectField
									v-if="field.type == 'select'"
									:id="'select_view_'+field.name"
									v-model="exam[field.name]"
									:item="field"
									:labelSize="labelSize"
									:inputSize="inputSize"
									:disabled="disabled"
								/>
								<BaseEditCheckboxField
									v-if="field.type == 'checkbox'"
									:id="'checkbox_view_'+field.name"
									v-model="exam[field.name]"
									:item="field"
									:labelSize="labelSize"
									:inputSize="inputSize"
									:disabled="disabled"
								/>
								<BaseEditRadioField
									v-if="field.type == 'radio'"
									:id="'radio_view_'+field.name"
									v-model="exam[field.name]"
									:item="field"
									:labelSize="labelSize"
									:inputSize="inputSize"
									:disabled="disabled"
								/>
								<BaseEditTextareaField
									v-if="field.type == 'textarea'"
									:id="'textarea_view_'+field.name"
									v-model="exam[field.name]"
									:item="field"
									:labelSize="labelSize"
									:inputSize="inputSize"
									:disabled="disabled"
								/>
								<BaseEditDatePickerField
									v-if="field.type == 'date' || field.type == 'birthday'"
									v-model="exam[field.name]"
									:item="field"
									:labelSize="labelSize"
									:inputSize="inputSize"
									:disabled="disabled"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<EditExamModal
			v-if="showEditModal"
			:key="'editModalId'+componentKey"
			:id="editModalId"
			:title="'Edit Exam'"
			:action="'Edit'"
			:color="'btn-primary'"
			:exam="exam"
			@close="closeModal"
			:index="'action_'+componentKey"
		>
		</EditExamModal>

		<DeleteExamModal
			v-if="showDeleteModal"
			:key="'deleteModalId'+componentKey"
			:id="deleteModalId"
			:title="'Delete Exam'"
			:action="'Delete'"
			:color="'btn-danger'"
			:exam="exam"
			@deleteExam="deleteExam"
			:index="'action_'+componentKey"
		>
		</DeleteExamModal>
	</div>
</div>
</template>

<script>

import { authComputed } from '../../../store/helpers.js'
import DeleteExamModal from './DeleteExamModal'
import EditExamModal from './EditExamModal'

export default {
	name: 'exam',
	components: {EditExamModal,  DeleteExamModal},
	directives: {},
	props: {
		id : {
			type : String,
			required : true
		},
		exam: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showEditModal : false,
			showDeleteModal : false,
			deleteModalId : '',
			editModalId : '',
			labelSize : 'col-lg-12',
			inputSize : 'col-lg-12',
			disabled : 'disabled',
		}
	},
	computed: {
        //...mapState(['user', 'animal']),
        ...authComputed,
	},
	mounted() {},

	methods: {
		deleteModal(adopter){
			this.showDeleteModal = true;
			this.componentKey+= 1
			this.deleteModalId = 'deleteExamModal_'+this.exam._id
		},
		deleteExam(exam_id, index){
			console.log('Delete Exam', exam_id)
			
			this.showDeleteModal = false;
		},
		editModal(){
			this.showEditModal = true;
			this.componentKey+= 1
			this.editModalId = 'editExamModal_'+this.exam._id
		},
		closeModal(){
			this.showEditModal = false;
			
		}
	},
}
</script>

<style lang="scss" scoped>

</style>