<template>
  <div>
    <div id="nav">
      <NavBar />
    </div>
      <router-view></router-view>
  </div>
</template>

<script>
import { authComputed } from '../store/helpers.js'
import NavBar from './../components/NavBar'
export default {
	name: 'animalProfileView',
	components: {
		NavBar,
	},
	data() {
		return {
			animals: null,
		}
	},
	computed: {
		...authComputed,
	},
}
</script>
