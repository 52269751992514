<template>
<div>
	<div v-if="isLoading">
		<div class="container space-top-1">
			<div class="d-flex justify-content-center">
				<div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="animal.location_status == 'Foster'">
		<ProfileHeader :animal="animal"></ProfileHeader>
		<ProfileNavbar @getWindowSize="getWindowSize"></ProfileNavbar>
		<div class="tab-content">
			<div id="about-section" class="tab-pane active" role="tabpanel" aria-labelledby="about">
				<ProfileAbout :animal="animal"></ProfileAbout>
			</div>
			<div id="adoptions-section" class="tab-pane" role="tabpanel" aria-labelledby="requests">
				<ProfileRequests :animal="animal" :requests="animal.requests" ></ProfileRequests>
			</div>
			
			<div id="medical-section" class="tab-pane" role="tabpanel" aria-labelledby="medical">
				<Medical></Medical>
			</div>
			<div id="notes-section" class="tab-pane fade" role="tabpanel" aria-labelledby="notes">
				<ProfileNotes></ProfileNotes>
			</div>
			<div id="files-section" class="tab-pane fade" role="tabpanel" aria-labelledby="files">
				<ProfileFiles :animal="animal" :files="animal.files"></ProfileFiles>
			</div>
			<div id="photos-section" class="tab-pane" role="tabpanel" aria-labelledby="photos">
				<ProfilePhotos ref="photos" :animal="animal"></ProfilePhotos>
			</div>
			<div id="videos-section" class="tab-pane" role="tabpanel" aria-labelledby="videos">
				<ProfileVideos ref="videos" :animal="animal"></ProfileVideos>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="container space-top-1">
      		<div class="row align-items-center">
        		<div class="col-sm mb-3 mb-sm-0 text-center">
					<h3>Sorry you do not have access to {{animal.animal_name}}'s profile.</h3>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import ProfileHeader from './AnimalProfileHeader'
import ProfileNavbar from './AnimalProfileNavbar'
import ProfileAbout from './about/AnimalProfileAbout'
import Medical from './medical/Medical'
import ProfileRequests from './requests/AnimalProfileRequests'
import ProfileFiles from './files/AnimalProfileFiles'
import ProfileNotes from './notes/AnimalProfileNotes'
import ProfilePhotos from './photos/AnimalProfilePhotos'
import ProfileVideos from './videos/AnimalProfileVideos'

export default {
	components: {
		ProfileHeader,
		ProfileNavbar,
		ProfileAbout,
		ProfileRequests,
		ProfileFiles,
		ProfileNotes,
		ProfilePhotos,
		ProfileVideos,
		Medical
	},
	data() {
		return {
			animal_id: this.$route.params.animal_id,
			isLoading : true,
		}
	},
	mounted() {
		this.getAnimal()
	},
	computed: {
		...mapState({
			user: 'user',
			animal: state => state.animal || {},
		}),
	},
	methods: {
		getWindowSize(){
			this.$refs.photos.getWindowSize()
		}, 
		getAnimal() {
			let data = {
				animal_id: this.$route.params.animal_id,
			}
			this.isLoading = true

			this.$store.dispatch('getAnimal', data)
			.then((response) => {
				this.isLoading = false
			})
			.catch((err) => console.log(err))
		},
	},
}
</script>

<style scoped>

</style>
