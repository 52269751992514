<template>
    <div>
        <div class="row mb-1">
            <div class="col-md-12">
                <div class="mb-1">
                    <p v-if="description && !readMoreActive" class="paragraphBreak" @click="$emit('click')">{{truncDescription}}</p>
                    <p v-if="description && readMoreActive" class="paragraphBreak" @click="$emit('click')">{{description}}</p>
                    <span v-if="description && description.length >300">
                        <a
                            class="link-collapse"
                            role="button"
                            @click="readMoreActive =! readMoreActive"
                        >
                            <span v-if="!readMoreActive" class="link-collapse-default">
                                Read more
                                <i class="fas fa-angle-down fa-sm ml-1"></i>
                            </span>
                            <span v-else class="link-collapse-active">
                                Read less
                                <i class="fas fa-angle-up fa-sm ml-1"></i>
                            </span>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseDecriptionField',
    components: {  },
    props : {
        description: {
            type : String,
        },
    },
    data() {
        return {
            readMoreActive : false,
        };
    },
    mounted() {
        
    },
    computed :{
        truncDescription() {
            if (this.description && this.description.length > 300){
			    return this.description.substring(0, 300)+'...'
            }else{
                return this.description
            }
		},
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
	.paragraphBreak {
        white-space: pre-line;;
    }
</style>