<template>
    <div class="row">
        <div :class="classSize">
            <hr>
        </div>
    </div>
</template>
<script>
    export default {
        props : {
            classSize: {
                type : String,
                default : 'col-sm-12'
            },
            
        },

        data () {
            return {
                
            }
        },
        created(){
            
        },
    }
</script>

<style scoped>

</style>