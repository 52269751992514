<template>
    <div>
        <h5>Current Weight</h5>
        <template>
            <p
                v-if="editable"
                class="editable-content"
                data-toggle="modal"
                :data-target="'#weight_add_modal_' + animal_id"
                @click="toggleEdit()"
            >
                <span v-if="weights.length && recentWeight.weight">
                    {{recentWeight.weight}} {{recentWeight.unit}}
                </span>
                <span v-else>
                    <i>Enter Weight</i>
                </span>
            </p>
            <p v-else>
                <span v-if="weights.length && recentWeight.weight">{{recentWeight.weight}} {{recentWeight.unit}}</span>
                <span v-else>---</span>
            </p>

            
        </template>

        <AddWeightModal
            v-if="showModal"
            :key="modalKey"
            :animal_id="animal_id"
            :weight="recentWeight"
        >
        </AddWeightModal>
    </div>
</template>

<script>
export default {
    name: 'BaseWeightField',
    components: {  },
    directives: {  },
    props : {
        animal_id: {
            type : String,
        },
        field: {
            type : Object,
            required :true
        },
        weights : {
            type : Array,
            default: () => []
        },
        editable : {
            type : Boolean,
        },
    },
    data() {
        return {
            componentKey: 0,
            showModal : false
        };
    },
    mounted() {
        
    },
    computed :{
        recentWeight(){
           return  this.weights && this.weights.length > 0 ? _.maxBy(this.weights, 'date') : null
        },
        modalKey() { return `${this.animal_id}_${this.componentKey}` }
    },
    methods: {
        toggleEdit() {
            this.showModal = true
            this.componentKey += 1
        }
    },
};
</script>

<style lang="scss" scoped>
.editable-content {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
}
</style>