var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"pageHeaderTabParent"}},[_c('div',{staticClass:"js-nav-scroller hs-nav-scroller-horizontal bg-white"},[_vm._m(0),_vm._m(1),_c('ul',{staticClass:"js-scroll-nav nav nav-tabs page-header-tabs bg-white",attrs:{"id":"pageHeaderTab","role":"tablist"}},[_vm._m(2),(_vm.animal && _vm.animal.location_status == 'Foster' && _vm.user.foster_adopt_requests != false)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#adoptions-section","role":"tab","aria-controls":"adoptions","aria-selected":"true"}},[_vm._v(" Requests "),(_vm.animal && _vm.activeRequests.length > 0)?_c('span',{staticClass:"badge badge-info badge-pill ml-1",attrs:{"role":"tab","aria-controls":"medial","aria-selected":"true"}},[_vm._v("+"+_vm._s(_vm.activeRequests.length))]):_vm._e()])]):_vm._e(),_vm._m(3),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#medical-section","role":"tab","aria-controls":"medical","aria-selected":"true"}},[_vm._v("Medical "),(_vm.animal && _vm.user.foster_view_medical != false && _vm.medicalDetails > 0)?_c('span',{staticClass:"badge badge-info badge-pill ml-1",attrs:{"role":"tab","aria-controls":"medial","aria-selected":"true"}},[_vm._v("+"+_vm._s(_vm.medicalDetails))]):_vm._e()])]),_vm._m(4),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#photos-section","role":"tab","aria-controls":"photos","aria-selected":"true"},on:{"click":_vm.getWindowSizeForPhotos}},[_vm._v(" Photos ")])]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"hs-nav-scroller-arrow-prev",staticStyle:{"display":"none"}},[_c('a',{staticClass:"hs-nav-scroller-arrow-link",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"fas fa-angle-left"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"hs-nav-scroller-arrow-next",staticStyle:{"display":"none"}},[_c('a',{staticClass:"hs-nav-scroller-arrow-link",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"fas fa-angle-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"data-toggle":"tab","href":"#about-section","role":"tab","aria-controls":"about","aria-selected":"true"}},[_vm._v("About")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#notes-section","role":"tab","aria-controls":"notes","aria-selected":"true"}},[_vm._v("Notes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#files-section","role":"tab","aria-controls":"files","aria-selected":"true"}},[_vm._v("Files")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#videos-section","role":"tab","aria-controls":"videos","aria-selected":"true"}},[_vm._v(" Videos ")])])
}]

export { render, staticRenderFns }