<template>
<div>
 	<div class="row">
        <div class="col-lg-5 mt-3 mb-2">
            <strong>Vaccination Doses:</strong>
             <hr>
        </div>
    </div>
    <div class="row m-b-xxs">
        <div class="col-lg-12">
            <div class="table-responsive tableFixHead" >
            	<table class="table table-hover">
            		<thead>
            			<tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Lot #</th>
                        <th>Expiration Date</th>
                        <th>By</th>
                        <th>Notes</th>
                    </tr>
            		</thead>
            		<tbody >
                         <tr v-for="dose, index in vaccination.schedule" :key="dose.id">
                        <td v-if="index == 0 ">Initial Dose</td>
                        <td v-else >Booster {{index}}</td>
                        <td>{{dose.date | formatDate}}</td>
                        <td class="text-capitalize" v-if="dose.status=='pending' && pastDue(dose)">
                            <span class="label label-danger">
                                Past Due
                            </span>
                        </td>
                        <td v-else class="text-capitalize">
                            <span class="badge"
                                :class="{
                                    'badge-primary': dose.status === 'pending',
                                    'badge-info': dose.status === 'completed',
                                    'badge-danger': dose.status === 'skipped',
                                    'badge-warning': dose.status === 'canceled'
                                    }"
                            >
                                {{dose.status}}
                            </span>
                        </td>
                        <td v-if="dose.lot" class="text-capitalize">{{dose.lot}}</td>
                        <td v-else> - </td>
                        <td v-if="dose.expiration_date" class="text-capitalize">{{dose.expiration_date | formatDate}}</td>
                        <td v-else> - </td>
                        <td v-if="dose.by">{{dose.by}}</td>
                        <td v-else> - </td>
                        <td v-if="dose.notes">{{dose.notes}}</td>
                        <td v-else> - </td>
                    </tr>
            		</tbody>
            	</table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'next_vaccination_dose',
    props : {
        vaccination : {
            type : Array|Object,
            required :true,
        },
    },
    data: function () {
        return { 
        }
    },
    methods : {
        pastDue (dose){
            return moment.unix(dose.date/1000).endOf('day').unix() < moment().unix();
        },

    }
} 

</script>
<style scoped>

.tableRowColor {
    background-color: #f9f9f9;
}
.tableFixHead          { overflow-y: auto; max-height: 400px; }
.tableFixHead thead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }

</style>