export default {
    data () {
      	return {
			windowHeight: 0,
			windowWidth: 0
		}
    },
    created () {
		//window.addEventListener('load', this.getWindowSize)
		window.addEventListener('resize', this.getWindowSize)
		this.getWindowSize()
    },
    mounted () {
      	this.getWindowSize()
    },
    beforeDestroy () {
    	//window.removeEventListener('load', this.getWindowSize)
		window.removeEventListener('resize', this.getWindowSize)
    },
  
    methods: {
		getWindowSize () {
			if (this.$el) {
				this.windowHeight = this.$el.clientHeight
				this.windowWidth = this.$el.clientWidth
			}
		}
    }
}